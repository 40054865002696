import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { EmentaComponent } from '../ementa/ementa.component';
import { UsuarioService } from '../api/usuario.service';
import { CampoService } from '../api/campo.service';
import { PageBaseService } from '../util/page-base.service';
import { Router } from '@angular/router';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { DatePipe } from '@angular/common';
import { QuillViewComponent } from 'ngx-quill';

@Component({
  selector: 'app-campo-edicao',
  templateUrl: './campo-edicao.component.html',
  standalone: false,
  styleUrls: ['./campo-edicao.component.scss'],
})
export class CampoEdicaoComponent extends PageBaseService implements OnInit {
  @Input()
  proposicao: any;
  @Input()
  chave: any;
  timeLista: any = [];
  @Input()
  modal;
  @Input()
  recarregarCallback;
  @Input()
  scope;
  textoHtml = '';
  titulo: any;
  exibeCalendario = false;
  dataSelecionadaStr: any;
  dataSelecionadaFormatada: any;
  @ViewChild('datePicker', { static: true }) datePicker;
  dataSelecionada: Date;
  @ViewChild('editorText') editorText!: QuillViewComponent;

  constructor(
    public usuarioService: UsuarioService,
    public campoService: CampoService,
    private modalController: ModalController,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected platform: Platform,
    protected vibration: Vibration,
    protected toastController: ToastController,
    protected router: Router
  ) {
    super(loadingController, alertCtrl, platform, router);
  }
  created(event: any) {
    if (this.chave) {
      this.textoHtml = this.proposicao.Schema[this.chave];
      this.titulo = this.chave;
      event.root.innerHTML = this.textoHtml;
    }
  }
  ngOnInit() {}

  blur(event) {
    this.textoHtml = event.editor.root.innerHTML;
  }
  onTitle($event) {
    this.titulo = $event.target.value;
  }
  campoValida() {
    return this.titulo && this.textoHtml;
  }

  selectionCancel(that) {
    that.modal.dismiss();
  }

  salvarClick() {
    // this.exibirLoading('Criando campo...');
    this.proposicao.Schema[this.titulo] = this.textoHtml;
    if (this.proposicao.Campo.Id) {
      this.campoService
        .alterar({
          Id: this.proposicao.Campo.Id,
          IdProjetoLei: this.proposicao.Id,
          IdUsuario: this.usuarioService.get().Id,
          Schema: JSON.stringify(this.proposicao.Schema),
        })
        .then((data: any[]) => {
          this.recarregarCallback();
          this.modal.dismiss();
        });
    } else {
      this.campoService
        .inserir({
          IdProjetoLei: this.proposicao.Id,
          IdUsuario: this.usuarioService.get().Id,
          Schema: JSON.stringify(this.proposicao.Schema),
        })
        .then((data: any[]) => {
          this.recarregarCallback();
          this.modal.dismiss();
        });
    }
    var model = {
      IdProjeto: this.proposicao.Id,
      IdUsuario: this.usuarioService.get().Id,
      IdUsuarioDemandante: this.usuarioService.get().Id,
      Texto: this.textoHtml,
      DataPrazo: this.dataSelecionadaStr,
      Situacao: 'Novo',
    };
    this.campoService.inserir(model).then(async (data: any) => {
      this.vibration.vibrate(2000);
      const toast = await this.toastController.create({
        message: 'Campo criada com sucesso!',
        duration: 3000,
        color: 'success',
      });
      toast.present();
      this.recarregarCallback(this.scope);
      this.ocultarLoading();
      this.vibration.vibrate(2000);
      this.selectionCancel(this);
    });
  }
}
