import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ProjetoService } from '../api/projeto.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { LoadingController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AutoridadeService } from '../api/autoridade.service';
import { Share } from '@capacitor/share';


@Component({
  selector: 'app-autoridade',
  templateUrl: './autoridade.component.html',
  standalone: false,
  styleUrls: ['./autoridade.component.scss'],
})
export class AutoridadeComponent extends PageBaseService implements OnInit {
  filtro = { Nome: '', Biografia: '', Next: false, Pagina: 0, QtdRegistroPagina: 100 };
  @Input()
  autoridade: any;
  Id: any;
  IdMinisterio: any;
  Nome: '';
  Ministerio: any;

  constructor(protected router: Router, public modalController: ModalController, protected platform: Platform, private projetoService: ProjetoService, private PageBaseService: PageBaseService, protected loadingCtrl: LoadingController, protected alertCtrl: AlertController, private AutoridadeService: AutoridadeService) {
    super(loadingCtrl, alertCtrl, platform, router);
  }

  ngOnInit() {
  }

  fechar() {
    this.modalController.dismiss({
    });
  }

  redeSocialClick(url) {
    window.open(url, '_system');
  }

  async share(destino, autoridade) {
    var texto = `Dá uma olhada no perfil do(a) ${autoridade.Nome + ", " + autoridade.Ministerio}`;
    var link = `https://legislapp.com.br/app/Autoridade/Ministro`;
    await Share.share({
      title: 'Perfil do(a) ' + autoridade.Nome,
      text: texto,
      url: link,
      dialogTitle: 'Compartilhamento',
    });
    // switch (destino) {
    //   case 'facebook': this.socialSharing.shareViaFacebook(texto, '', link); break;
    //   case 'whatsapp': this.socialSharing.shareViaWhatsApp(texto, '', link); break;
    //   case 'instagram': this.socialSharing.share(texto, autoridade.Foto, link, null); break;
    //   case 'twitter': this.socialSharing.shareViaTwitter(texto, '', link + '?s=19'); break;
    // }
  }

  onImgError(item) {
    if (item.Autoria == 'Senado Federal') {
      item.Foto = '/assets/logo-senado.png'
    }
    else if (item.Autoria == 'Câmara dos Deputados') {
      item.Foto = '/assets/logo-camara.png';
    }

    else if (item.Autoria == 'Poder Executivo' || item.Autoria == "Presidência da República") {
      item.Foto = '/assets/poderexecutivo.png';
    }

    else {
      item.Foto = '/assets/imagem-perfil.jpg';
    }
  }

}
