import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { EmentaComponent } from '../ementa/ementa.component';
import { UsuarioService } from '../api/usuario.service';
import { TarefaService } from '../api/tarefa.service';
import { PageBaseService } from '../util/page-base.service';
import { Router } from '@angular/router';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { DatePipe } from '@angular/common';

@Component({
  standalone: false,
  selector: 'app-tarefa-edicao',
  templateUrl: './tarefa-edicao.component.html',
  styleUrls: ['./tarefa-edicao.component.scss'],
})
export class TarefaEdicaoComponent extends PageBaseService implements OnInit {
  @Input()
  idProjeto: any;
  timeLista: any = [];
  @Input()
  modal;
  @Input()
  recarregarCallback;
  @Input()
  scope;
  idUsuarioResponsavel: any;
  textoHtml: any;
  exibeCalendario = false;
  dataSelecionadaStr: any;
  dataSelecionadaFormatada: any;
  @ViewChild('datePicker', { static: true }) datePicker;
  dataSelecionada: Date;

  constructor(
    public usuarioService: UsuarioService,
    public TarefaService: TarefaService,
    private modalController: ModalController,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected platform: Platform,
    protected vibration: Vibration,
    protected toastController: ToastController,
    protected router: Router
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
    this.carregarListaPlano();
  }
  openDatePicker() {
    this.exibeCalendario = true;
  }
  fecharCalendario() {
    this.exibeCalendario = false;
  }
  dataCalendarioChange(event) {
      this.dataSelecionada = new Date(event.detail.value);
    this.dataSelecionadaStr = event.detail.value;
    this.dataSelecionadaFormatada = new DatePipe("en-US").transform(this.dataSelecionada, 'dd/MM/yyyy');
    this.exibeCalendario = false;
  }
  carregarListaPlano() {
    var usuario = this.usuarioService.get();
    this.usuarioService.listarUsuariosPlano(usuario.Id).then((data: any) => {
      this.timeLista = data.map((x) => {
        return {
          Titulo: x.Nome + ' - ' + x.Email,
          Id: x.Id,
        };
      });
    });
  }
  blur(event){
    this.textoHtml = event.editor.root.innerHTML;
  }

  tarefaValida(){
    return this.textoHtml && this.dataSelecionadaStr && this.idUsuarioResponsavel;
  }

  selectionCancel(that) {
    that.modal.dismiss();
  }

  selectionChange(itens, that) {
    that.idUsuarioResponsavel = itens[0];
  }
  salvarClick() {
    this.exibirLoading('Criando tarefa e notificando responsável...');

    var model = {
      IdProjeto: this.idProjeto,
      IdUsuario: this.idUsuarioResponsavel,
      IdUsuarioDemandante: this.usuarioService.get().Id,
      Texto: this.textoHtml,
      DataPrazo: this.dataSelecionadaStr,
      Situacao: 'Novo',
    };
    this.TarefaService.inserir(model).then(async (data: any) => {
      this.vibration.vibrate(2000);
      const toast = await this.toastController.create({
        message: 'Tarefa criada com sucesso!',
        duration: 3000,
        color: 'success',
      });
      toast.present();
      this.recarregarCallback(this.scope);
      this.ocultarLoading();
      this.vibration.vibrate(2000);
      this.selectionCancel(this);
    });
  }
}
