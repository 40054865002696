import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AgendaSessaoService } from '../api/agenda-sessao.service';
import { AssinaturaService } from '../api/assinatura.service';
import { UsuarioService } from '../api/usuario.service';
import { AgendaPage } from '../agenda/agenda.page';
import {
  ActionSheetController,
  AlertController,
  IonModal,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { Router } from '@angular/router';
import { ProjetoService } from '../api/projeto.service';
import { TypeaheadPage } from '../typeahead/typeahead.page';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { AgendaAgentePublicoService } from '../api/agenda-agente-publico.service';
import { AgendaParlamentarService } from '../api/agenda-parlamentar.service';
enum TipoFiltro {
  Radar,
  Autoridade,
}
@Component({
  selector: 'app-agenda-parlamentar',
  templateUrl: './agenda-parlamentar-component.page.html',
  styleUrls: ['./agenda-parlamentar-component.page.scss'],
  standalone: false,
})
export class AgendaParlamentarComponentPage
  extends PageBaseService
  implements OnInit
{
  @Input()
  idParlamentar: any;
  public dataSelecionadaStr;
  exibirSpinning = false;
  public date;
  dataSelecionada: Date;
  diaSemanaLista: string[];
  diaMesLista: any[];
  indexDiaAtual: any;
  mesAtual: any;
  filtro: any = undefined;
  tipoFiltro: TipoFiltro;
  itensSelecionados: any = [];
  @ViewChild('datePicker', { static: true }) datePicker;
  exibeCalendario = false;
  agendaSessaoLista: any;
  plano: any = null;
  idUsuario: any = undefined;
  listaAutoridade: any;
  radarLista: any;
  @ViewChild('picker', { static: true }) modalPicker!: any;
  modal: HTMLIonModalElement;
  agendaSessaoListaBkp: any;
  radarListaBkp: any;
  constructor(
    private agendaParlamentarService: AgendaParlamentarService,
    protected router: Router,
    protected platform: Platform,
    private modalController: ModalController,
    private assinaturaService: AssinaturaService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private projetoService: ProjetoService,
    private usuarioService: UsuarioService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    public actionSheetController: ActionSheetController
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((plano) => {
        this.plano = plano;
      });
    this.carregarCalendarioUi();
    this.carregarAgenda();
  }
  formatarHorario(input: string) {
    const parts = input.split(':');
    return parts[0] + ':' + parts[1];
  }
  openDatePicker() {
    this.exibeCalendario = true;
  }
  removerFiltro(item) {
    this.filtro = this.filtro.filter((x) => x.Id != item.Id);
    this.idUsuario = undefined;
  }

  abrirModalRadar() {
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        this.radarLista = data.map((x) => {
          return {
            Titulo: x.Titulo,
            Id: x.Id,
          };
        });
        this.radarListaBkp = data;

        this.modalController.dismiss();
        var that = this;
        this.modal = await this.modalController.create({
          component: TypeaheadPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            items: this.radarLista,
            selectedItems: this.itensSelecionados,
            selectionChange: (itens) => {
              that.selecaoChanged(itens, that);
            },
            selectionCancel: () => {
              this.modal.dismiss();
            },
            title: 'Filtrar por Radar',
          },
        });
        this.ocultarLoading();

        return await this.modal.present();
      });
  }
  selecaoChangedAutoridade(itens, that) {
    this.itensSelecionados = itens;
    this.agendaSessaoLista.Agendas = this.agendaSessaoListaBkp.Agendas.filter(
      (x) =>
        this.itensSelecionados.indexOf(x.IdAgentePublico) > -1 ||
        itens.length == 0
    );
    this.modal.dismiss();
  }
  async abrirModalAutoridade() {
    this.listaAutoridade = this.agendaSessaoListaBkp.Agendas.filter(
      (x) => x.IdAgentePublicoNavigation != null
    ).map((x) => {
      return {
        Titulo:
          x.IdAgentePublicoNavigation.Nome +
          ' - ' +
          x.IdAgentePublicoNavigation.IdOrgaoNavigation.Nome,
        Id: x.IdAgentePublicoNavigation.Id,
      };
    });
    this.listaAutoridade = [
      ...new Map(
        this.listaAutoridade.map((item) => [item['Id'], item])
      ).values(),
    ];
    this.modalController.dismiss();
    var that = this;
    this.modal = await this.modalController.create({
      component: TypeaheadPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        items: this.listaAutoridade,
        selectedItems: this.itensSelecionados,
        selectionChange: (itens) => {
          that.selecaoChangedAutoridade(itens, that);
        },
        selectionCancel: () => {
          this.modal.dismiss();
        },
        title: 'Filtrar por Autoridade',
      },
    });
    this.ocultarLoading();

    return await this.modal.present();
  }
  async filterClick() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Filtrar agendas por',
      cssClass: '',
      buttons: [
        {
          text: 'Autoridade',
          icon: 'person-outline',
          handler: () => {
            this.tipoFiltro = TipoFiltro.Autoridade;
            this.abrirModalAutoridade();
          },
        },
        {
          text: 'Seus radares de monitoramento',
          icon: 'radio-outline',
          handler: () => {
            this.tipoFiltro = TipoFiltro.Radar;
            this.abrirModalRadar();
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            this.filtro = '';
            this.tipoFiltro = undefined;
          },
        },
      ],
    });
    await actionSheet.present();
  }
  selecaoChanged(itens: any, that) {
    this.itensSelecionados = itens;
    this.agendaSessaoLista.Sessoes = this.agendaSessaoLista.Sessoes;
  }

  async agendaClick(agenda) {
    if (this.plano) {
      const modal = await this.modalController.create({
        component: AgendaPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          agenda,
        },
      });
      return await modal.present();
    } else {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
    }
  }
  carregarCalendarioUi() {
    this.dataSelecionada = new Date();
    if (this.dataSelecionadaStr)
      this.dataSelecionada = new Date(this.dataSelecionadaStr);
    this.diaSemanaLista = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    this.diaMesLista = [];
    var d = new Date(this.dataSelecionada);
    d.setDate(d.getDate() - d.getDay());

    for (let index = 0; index < 7; index++) {
      this.diaMesLista.push(d.getDate().toString());
      d.setDate(d.getDate() + 1);
    }
    this.indexDiaAtual = this.dataSelecionada.getDay();
    this.mesAtual = this.dataSelecionada.toLocaleDateString('default', {
      month: 'long',
    });
    // this.exibeCalendario = false;
  }
  fecharCalendario() {
    this.exibeCalendario = false;
  }
  dataCalendarioChange(event) {
    this.dataSelecionadaStr = event.detail.value;
    this.carregarCalendarioUi();
    this.carregarAgenda();
    this.exibeCalendario = false;
  }
  carregarAgenda() {
    // this.exibirLoading('');
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    // this.agendaSessaoService.obterTexto(date.split('T')[0]).then((data) => {
    //   this.textoAgenda = data;
    //   // this.ocultarLoading();
    // });
    tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    var funcao = this.agendaParlamentarService.listar(
      date.split('T')[0],
      this.idParlamentar
    );
    this.exibirSpinning = true;
    funcao.then((data) => {
      this.exibirSpinning = false;

      this.agendaSessaoLista = data;
      this.agendaSessaoListaBkp = Object.assign({}, data);
      // this.ocultarLoading();
    });
  }

  diaChanged(ev: any) {
    this.dataSelecionada.setDate(
      this.dataSelecionada.getDate() -
        (this.indexDiaAtual - parseInt(ev.detail.value))
    );
    this.indexDiaAtual = ev.detail.value;
    this.carregarAgenda();
  }
}
