import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { ConfiguracaoNotificacaoService } from '../api/configuracao-notificacao.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.page.html',
  standalone: false,
  styleUrls: ['./contato.page.scss'],
})
export class ContatoPage extends PageBaseService implements OnInit {
  usuario: any;
  configuracaoNotificacaoVM: any;

  constructor(protected platform:Platform, private menuService: MenuService, private usuarioService: UsuarioService, private modalController: ModalController, protected loadingController: LoadingController, protected alertCtrl: AlertController,
    private zone: NgZone, protected router: Router) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
  }

  fechar() {
    this.modalController.dismiss({
    });
  }
}
