import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { BYPASS_AUTH } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class BuscaService extends ApiConfig {
  termosData: Object;
  constructor(private http: HttpClient) {
    super();
  }

  listar(filtro) {
    try {
      filtro.QtdRegistroPagina = 100;
      return new Promise(async (resolve) => {
        this.http.post('https://api.legislapp.com.br/Busca/Listar', filtro).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            );
          }
        );
      });
    } catch (error) {
      // alert(error);
    }
  }

  listarTermos() {
    try {
      if (this.termosData)
        return new Promise(async (resolve) => {
          resolve([this.termosData]);
        });
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/Busca/listarTermos', { context: new HttpContext().set(BYPASS_AUTH, true) }).subscribe(
          (data) => {
            this.termosData = data;
            resolve(data);
          },
          (error) => {
          }
        );
      });
    } catch (error) {
      // alert(error);
    }
  }
}
