import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ModalController,
  LoadingController,
  AlertController,
} from '@ionic/angular';
import { UsuarioService } from '../api/usuario.service';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { RadarMonitoramentoPage } from '../radar-monitoramento/radar-monitoramento.page';
import { PageBaseService } from '../util/page-base.service';
import { Animation, AnimationController } from '@ionic/angular';
import { RetrospectivaService } from '../api/retrospectiva.service';
import { ActivatedRoute } from '@angular/router';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-retrospectiva',
  templateUrl: './retrospectiva.component.html',
  standalone: false,
  styleUrls: ['./retrospectiva.component.scss'],
})
export class RetrospectivaComponent extends PageBaseService {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  slideOptions = {
    initialSlide: 1,
    loop: false,
    autoplayDisableOnInteraction: false,
  };
  exibirSlide1 = false;
  @Input()
  model: any;
  constructor(
    public modalController: ModalController,
    protected loadingCtrl: LoadingController,
    protected usuarioService: UsuarioService,
    protected alertCtrl: AlertController,
    private animationCtrl: AnimationController,
    private retrospectivaService: RetrospectivaService,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute
  ) {
    super(loadingCtrl, alertCtrl, null, null);
  }

  ngOnInit() {
    var that = this;
    var indexTimeout = 0;
    setTimeout(() => {
      var interval = setInterval(function () {
        that.swiperRef.nativeElement.swiper.slideNext(1000);
        indexTimeout++;
        if (indexTimeout >= 4) clearInterval(interval);
      }, 8000);
    }, 2000);
  }

  carregar() {
    //this.retrospectivaService.gerar(this.usuarioService.get().Id).then(x => this.model = x);
  }
  ngAfterViewInit() {
  }

  fechar() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    localStorage.setItem('RETROSPECTIVA', 'TRUE');
    this.modalController.dismiss({});
  }

  ionSlideDidChange() {
    var that = this;
    this.zone.run(() => {
      var index = that.swiperRef?.nativeElement.swiper.activeIndex;
      if (index == 1) {
        this.exibirSlide1 = true;
        var a = this.animationCtrl
          .create()
          .addElement(document.querySelector('.hide1'))
          .duration(8000)
          .iterations(1)
          .keyframes([{ offset: 0.5, transform: 'scale(0.9)' }])
          .fromTo('opacity', '1', '0.8');
        a.play();

        var b = this.animationCtrl
          .create()
          .addElement(document.querySelector('.show1'))
          .duration(8000)
          .keyframes([
            { offset: 0, transform: 'scale(1)' },
            { offset: 0.5, transform: 'scale(1.2)' },
          ])
          .iterations(Infinity)
          .fromTo('opacity', '0.9', '1.1');
        b.play();
      } else if (index == 2 || index == 3) {
        this.exibirSlide1 = true;

        var b = this.animationCtrl
          .create()
          .addElement(document.querySelector('.show2'))
          .duration(8000)
          .keyframes([
            { offset: 0, transform: 'scale(1)' },
            { offset: 0.5, transform: 'scale(1.2)' },
          ])
          .iterations(Infinity)
          .fromTo('opacity', '0.8', '1');
        b.play();

        var c = this.animationCtrl
          .create()
          .addElement(document.querySelector('.show3'))
          .duration(8000)
          .keyframes([
            { offset: 0, transform: 'scale(1)' },
            { offset: 0.5, transform: 'scale(1.2)' },
          ])
          .iterations(Infinity)
          .fromTo('opacity', '0.8', '1');
        c.play();
      }
    });
  }

  // slidesDidLoad(slides: IonSlides) {
  //   this.slides = slides;
  //   var that = this;
  //   setTimeout(function () {
  //     that.slides.slideNext();
  //   }, 4000);
  // }

  async compartilharClick() {
    // this.exibirLoading('Aguarde...');
    Share.share({
      title: 'Essa é a minha Retrospectiva Legislapp',
      text: 'Confira o que aconteceu no legislativo',
      url: 'https://app.legislapp.com.br/#/tabs/retrospectiva/',
      dialogTitle: 'Legislapp - Monitoramento Legislativo',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
      });
  }

  ocultarLoading() {
    var qtd = 0;
    setTimeout(() => {
      var interval = setInterval(() => {
        if (this.loading != null) {
          this.loading.dismiss();
          clearInterval(interval);
        } else {
          qtd++;
        }
        if (qtd == 5) {
          clearInterval(interval);
        }
      }, 1000);
    }, 1000);
  }
}
