import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
// import {
//   AppUpdate,
//   AppUpdateAvailability,
// } from '@capawesome/capacitor-app-update';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  ructor() {}
  getCurrentAppVersion = async () => {
    // var result = await AppUpdate.getAppUpdateInfo();
    // if (Capacitor.getPlatform() === 'android') {
    //   return result.currentVersionCode;
    // } else {
    //   return result.currentVersionName;
    // }
  };

  getAvailableAppVersion = async () => {
    // var result = await AppUpdate.getAppUpdateInfo();
    // if (Capacitor.getPlatform() === 'android') {
    //   return result.availableVersionCode;
    // } else {
    //   return result.availableVersionName;
    // }
  };

  openAppStore = async () => {
    // await AppUpdate.openAppStore();
  };

  performImmediateUpdate = async () => {
    // var result = await AppUpdate.getAppUpdateInfo();
    // if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    //   return;
    // }
    // if (result.immediateUpdateAllowed) {
    //   await AppUpdate.performImmediateUpdate();
    // }
  };

  startFlexibleUpdate = async () => {
    // var result = await AppUpdate.getAppUpdateInfo();
    // if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    //   return;
    // }
    // if (result.flexibleUpdateAllowed) {
    //   await AppUpdate.startFlexibleUpdate();
    // }
  };

  completeFlexibleUpdate = async () => {
    // await AppUpdate.completeFlexibleUpdate();
  };
}
