import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';

import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { AssinaturaService } from '../api/assinatura.service';
import { ConfiguracaoNotificacaoService } from '../api/configuracao-notificacao.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@Component({
  selector: 'app-meu-plano',
  templateUrl: './meu-plano.page.html',
  standalone: false,
  styleUrls: ['./meu-plano.page.scss'],
})
export class MeuPlanoPage extends PageBaseService implements OnInit {
  usuario: any;
  configuracaoNotificacaoVM: any;
  plano: any;
  dataPlano: unknown;
  constructor(
    public actionSheetController: ActionSheetController,
    private menuService: MenuService,
    private usuarioService: UsuarioService,
    private modalController: ModalController,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected platform: Platform,
    protected router: Router,
    private zone: NgZone,
    private toastController: ToastController,
    private vibration: Vibration,
    private assinaturaService: AssinaturaService
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
    this.carregarLista();
  }
  somenteLeituraClick(convite) {
    this.usuarioService.somenteLeitura({
      Email: convite.Email,
      SomenteLeitura: !convite.SomenteLeitura,
    });
  }
  async conviteClick(convite) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Deseja remover o usuário ' + convite.Email + '?',
      cssClass: '',
      buttons: [
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.exibirLoading('Removendo membro do seu plano...');
            this.usuarioService.removerPlano(convite).then(async () => {
              this.plano.ConviteLista = this.plano.ConviteLista.filter(
                (x) => x.Id != convite.Id
              );
              this.vibration.vibrate(2000);
              const toast = await this.toastController.create({
                message: 'Membro removido com sucesso!',
                duration: 3000,
                color: 'danger',
              });
              toast.present();
              this.ocultarLoading();
            });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  carregarLista() {
    this.usuario = this.usuarioService.get();
    this.usuarioService.obterPlano(this.usuario.Id).then((data) => {
      this.plano = data;
      this.plano.ConviteLista.forEach((convite) => {
        this.usuarioService
          .permissao(convite.Email)
          .then((data) => (convite.SomenteLeitura = data));
      });
      if (!this.plano) this.assinaturaService.inicializar(this.usuario.id);
    });
  }

  async exibirConvidarUsuario() {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      message:  '<b class="label-compartilhar">Compartilhar todos os radares do plano com o novo membro</b><br/><ion-checkbox id="compartilharRadares"></ion-checkbox>',
      header: 'Adicione pessoas ao seu Plano',
      subHeader: 'Digite o e-mail para enviarmos o link de ingresso.',
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'E-mail (Obrigatório)',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (email) => {
            var checkEl = document.getElementById('compartilharRadares') as any;
            var compartilharRadares = checkEl.value == 'on';
            this.convidarUsuario(email.email, compartilharRadares);
          },
        },
      ],
    });
    alertComponent.present();
  }

  convidarUsuario(email, compartilharRadares) {
    this.usuarioService
      .convidarPlano({
        IdUsuarioAdmin: this.usuarioService.get().Id,
        Email: email,
        IdPlano: this.plano.Id,
        CompartilharRadares: compartilharRadares
      })
      .then(async (data: any) => {
        this.carregarLista();
        const actionSheet = await this.actionSheetController.create({
          header: 'Link de ingresso enviado por e-mail!',
          cssClass: '',
          buttons: [
            {
              text: 'Compartilhar Link',
              icon: 'share-social-outline',
              handler: () => {
                Share.share({
                  title: 'Link de ingresso - Legislapp',
                  text: 'Segue link de ingresso do meu plano no Legislapp',
                  url: data,
                  dialogTitle: 'Link de ingresso - Legislapp',
                })
                  .then((arg) => {})
                  .catch((error) => {});
              },
            },
            {
              text: 'Copiar Link',
              icon: 'copy-outline',
              handler: async () => {
                await Clipboard.write({
                  string: data,
                });
              },
            },
            {
              text: 'Cancelar',
              icon: 'close',
              role: 'cancel',
              handler: () => {},
            },
          ],
        });
        await actionSheet.present();
      });
  }
  planoClick() {
    this.assinaturaService.assinar();
  }

  fechar() {
    this.modalController.dismiss({});
  }
}
