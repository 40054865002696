import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AgendaSessaoService } from '../api/agenda-sessao.service';
import { AssinaturaService } from '../api/assinatura.service';
import { UsuarioService } from '../api/usuario.service';
import { AgendaPage } from '../agenda/agenda.page';
import {
  ActionSheetController,
  AlertController,
  IonModal,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { Router } from '@angular/router';
import { ProjetoService } from '../api/projeto.service';
import { TypeaheadPage } from '../typeahead/typeahead.page';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { AgendaAgentePublicoService } from '../api/agenda-agente-publico.service';
import { AutoridadeService } from '../api/autoridade.service';
import { OverlayEventDetail } from '@ionic/core';
enum TipoFiltro {
  Radar,
  Autoridade,
}
@Component({
  standalone: false,
  selector: 'app-agenda-executivo-component',
  templateUrl: './agenda-executivo-component.page.html',
  styleUrls: ['./agenda-executivo-component.page.scss'],
})
export class AgendaExecutivoComponentPage
  extends PageBaseService
  implements OnInit
{
  @Input()
  idComissao: any;
  @ViewChild('searchBar') searchbar: any;
  public dataSelecionadaStr;
  exibirSpinning = false;
  diaMesListaUtil = [];
  textoBusca = '';
  public date;
  dataSelecionada = new Date();
  diaSemanaLista: string[];
  diaSemanaListaUtil: string[];
  diaMesLista: any[];
  visualizacao = 'semana';
  indexDiaAtual: any;
  mesAtual: any;
  filtro: any = undefined;
  tipoFiltro: TipoFiltro;
  itensSelecionados: any = [];
  @ViewChild('modalDatetime') modalDatetime: any;

  exibeCalendario = false;
  agendaSessaoLista: any;
  plano: any = null;
  idUsuario: any = undefined;
  listaAutoridade: any;
  listaAutoridadeBkp: any;
  exibeSelecaoExport = false;
  radarLista: any;
  modal: HTMLIonModalElement;
  agendaSessaoListaBkp: any;
  radarListaBkp: any;
  locaisLista: any;
  locaisListaBkp: any;

  constructor(
    private agendaAgentePublicoService: AgendaAgentePublicoService,
    protected router: Router,
    protected platform: Platform,
    private modalController: ModalController,
    private assinaturaService: AssinaturaService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private projetoService: ProjetoService,
    private usuarioService: UsuarioService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected autoridadeService: AutoridadeService,
    public actionSheetController: ActionSheetController
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((plano) => {
        this.plano = plano;
      });
    this.carregarListaAutoridade();
    this.carregarCalendarioUi();
    this.carregarAgenda();
  }
  formatarHorario(input: string) {
    const parts = input.split(':');
    return parts[0] + ':' + parts[1];
  }
  openDatePicker() {
    this.modalDatetime.nativeElement.present();
    this.exibeCalendario = true;
  }
  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      // this.message = `Hello, ${ev.detail.data}!`;
    }
  }
  limparBusca() {
    this.textoBusca = '';
    this.agendaSessaoLista = this.agendaSessaoListaBkp;
  }
  removerFiltro(item) {
    this.filtro = this.filtro.filter((x) => x != item);
    var autoridade = this.listaAutoridadeBkp.filter(
      (x) => item == x.Nome + ' - ' + x.IdOrgaoNavigation.Nome
    )[0];
    this.itensSelecionados = this.itensSelecionados.filter(
      (x) => x != autoridade.Id
    );
    this.carregarAgenda();
    this.fecharCalendario();
  }
  async abrirMenu() {
    const actionSheet = await this.actionSheetController.create({
      cssClass: '',
      buttons: [
        {
          text: 'Filtrar por data',
          icon: 'calendar-outline',
          handler: () => {
            this.openDatePicker();
          },
        },
        {
          text: 'Local',
          icon: 'locate-outline',
          handler: () => {
            this.tipoFiltro = TipoFiltro.Autoridade;
            this.abrirModalLocal();
          },
        },
        {
          text: 'Autoridade',
          icon: 'person-outline',
          handler: () => {
            this.tipoFiltro = TipoFiltro.Autoridade;
            this.abrirModalAutoridade();
          },
        },
        // {
        //   text: 'Semana',
        //   icon: 'grid-outline',
        //   handler: () => {
        //     this.agendaSessaoLista = undefined;
        //     this.visualizacao = 'semana';
        //     this.carregarCalendarioUi();
        //     this.carregarAgenda();
        //   },
        // },
        // {
        //   text: 'Dia',
        //   icon: 'square-outline',
        //   handler: () => {
        //     this.agendaSessaoLista = undefined;
        //     this.visualizacao = 'dia';
        //     this.carregarCalendarioUi();
        //     this.carregarAgenda();
        //   },
        // },
        // {
        //   text: 'Exportar',
        //   icon: 'document-outline',
        //   handler: () => {
        //     this.exibeSelecaoExport = true;
        //   },
        // },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  exibeAgenda(agenda) {
    return (
      (agenda.Comissao &&
        this.itensSelecionados.indexOf(agenda.Comissao.Id) >= 0) ||
      this.itensSelecionados.length == 0
    );
  }
  getAgendaDia(horario, dia) {
    horario = this.formatarHorario(horario);
    var result = this.agendaSessaoLista.Agendas.filter((x) => {
      // console.log(
      //   this.formatarHorario(x.DataInicio.split('T')[1]) + '==' + horario
      // );
      // console.log(new Date(x.DataInicio).getDate().toString() + '==' + dia);
      return (
        this.formatarHorario(x.DataInicio.split('T')[1]) == horario &&
        new Date(x.DataInicio).getMonth() == this.dataSelecionada.getMonth() &&
        new Date(x.DataInicio).getDate().toString() == dia
      );
    });
    return result;
  }
  abrirModalRadar() {
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        this.radarLista = data.map((x) => {
          return {
            Titulo: x.Titulo,
            Id: x.Id,
          };
        });
        this.radarListaBkp = data;

        this.modalController.dismiss();
        var that = this;
        this.modal = await this.modalController.create({
          component: TypeaheadPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            items: this.radarLista,
            selectedItems: this.itensSelecionados,
            selectionChange: (itens) => {
              that.selecaoChanged(itens, that);
            },
            selectionCancel: () => {
              this.modal.dismiss();
            },
            title: 'Filtrar por Radar',
          },
        });
        this.ocultarLoading();

        return await this.modal.present();
      });
  }
  carregarListaAutoridade() {
    this.exibirSpinning = true;
    this.autoridadeService
      .listar(this.dataSelecionada.toISOString().slice(0, -1).split('T')[0])
      .then((data) => {
        this.listaAutoridade = data;
        this.listaAutoridadeBkp = data;
        // this.exibirSpinning = false;
      });
  }
  selecaoChangedAutoridade(itens, that) {
    this.searchbar.nativeElement.value = '';
    this.itensSelecionados = itens;
    this.filtro = this.listaAutoridadeBkp
      .filter((x) => itens.includes(x.Id))
      .map((x) => x.Nome + ' - ' + x.IdOrgaoNavigation.Nome);

    this.carregarAgenda();
    this.modal.dismiss();
  }
  highlightedDates = (isoString) => {
    const date = new Date(isoString);
    const utcDay = date.getUTCDate();
    if (
      this.agendaSessaoListaBkp.Agendas.filter(
        (x) => x.DataInicio.slice(0, -1).split('T')[0] == isoString
      ).length > 0
    )
      return {
        textColor: 'white',
        backgroundColor: this.agendaSessaoListaBkp.Agendas.filter(
          (x) => x.DataInicio.slice(0, -1).split('T')[0] == isoString
        )[0].Cor,
      };
    return undefined;
  };
  selecaoChangedLocal(itens, that) {
    this.itensSelecionados = itens;
    this.filtro = itens;
    this.agendaSessaoLista.Agendas = this.agendaSessaoListaBkp.Agendas.filter(
      (x) => this.itensSelecionados.indexOf(x.Local) > -1 || itens.length == 0
    );
    this.modal.dismiss();
  }
  async abrirModalAutoridade() {
    this.listaAutoridade = [
      ...new Map(
        this.listaAutoridadeBkp.map((item) => {
          item.Titulo =
            '(' +
            item.Cargo +
            ') ' +
            (item.Nome + ' - ' + item.IdOrgaoNavigation.Nome).toUpperCase();
          var result = [item['Id'], item];
          return result;
        })
      ).values(),
    ];
    this.modalController.dismiss();
    var that = this;
    this.modal = await this.modalController.create({
      component: TypeaheadPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        items: this.listaAutoridade,
        selectedItems: this.itensSelecionados,
        selectionChange: (itens) => {
          this.itensSelecionados = [];
          that.selecaoChangedAutoridade(itens, that);
        },
        itemChange: (itens) => {
          // that.selecaoChangedAutoridade(itens, that);
        },
        selectionCancel: () => {
          this.modal.dismiss();
        },
        title: 'Filtrar por Autoridade',
      },
    });
    this.ocultarLoading();

    return await this.modal.present();
  }
  async abrirModalLocal() {
    this.locaisLista = [
      ...new Map(
        this.locaisListaBkp.map((item) => {
          var result = [item, { Titulo: item, Id: item }];
          return result;
        })
      ).values(),
    ];
    this.modalController.dismiss();
    var that = this;
    this.modal = await this.modalController.create({
      component: TypeaheadPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        items: this.locaisLista,
        selectedItems: this.itensSelecionados,
        selectionChange: (itens) => {
          that.selecaoChangedLocal(itens, that);
        },
        itemChange: (itens) => {},
        selectionCancel: () => {
          this.modal.dismiss();
        },
        title: 'Filtrar por Local',
      },
    });
    this.ocultarLoading();

    return await this.modal.present();
  }
  async filterClick() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Filtrar agendas por',
      cssClass: '',
      buttons: [
        {
          text: 'Autoridade',
          icon: 'person-outline',
          handler: async () => {
            this.tipoFiltro = TipoFiltro.Autoridade;
            await this.abrirModalAutoridade();
          },
        },
        {
          text: 'Seus radares de monitoramento',
          icon: 'radio-outline',
          handler: () => {
            this.tipoFiltro = TipoFiltro.Radar;
            this.abrirModalRadar();
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            this.filtro = '';
            this.tipoFiltro = undefined;
          },
        },
      ],
    });
    await actionSheet.present();
  }
  selecaoChanged(itens: any, that) {
    this.itensSelecionados = itens;
    this.agendaSessaoLista.Sessoes = this.agendaSessaoLista.Sessoes;
  }

  async agendaClick(agenda) {
    if (this.plano) {
      const modal = await this.modalController.create({
        component: AgendaPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          agenda,
        },
      });
      return await modal.present();
    } else {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
    }
  }
  corData(diaMes) {
    var cor = 'light';
    if (diaMes == this.dataSelecionada.getDate().toString()) {
      cor = 'dark';
    }
    return cor;
  }

  carregarCalendarioUi() {
    this.diaMesListaUtil = [];
    this.diaMesLista = [];
    if (!this.dataSelecionada) this.dataSelecionada = new Date();
    if (this.dataSelecionadaStr)
      this.dataSelecionada = new Date(this.dataSelecionadaStr);
    this.diaSemanaLista = ['S', 'T', 'Q', 'Q', 'S'];
    this.diaSemanaListaUtil = ['S', 'T', 'Q', 'Q', 'S'];
    this.diaMesLista = [];
    var d = new Date(this.dataSelecionada);
    d.setDate(d.getDate() - d.getDay());

    for (let index = 0; index < 7; index++) {
      this.diaMesLista.push(d.getDate().toString());
      d.setDate(d.getDate() + 1);
    }

    d = new Date(this.dataSelecionada);
    d.setDate(d.getDate() + 1 - d.getDay());
    for (let index = 0; index < 5; index++) {
      this.diaMesListaUtil.push(d.getDate().toString());
      d.setDate(d.getDate() + 1);
    }
    this.indexDiaAtual = this.dataSelecionada.getDay();
    this.mesAtual = this.dataSelecionada.toLocaleDateString('default', {
      month: 'long',
    });
    // this.exibeCalendario = false;
  }
  getQtdAgenda(autoridade) {
    return this.agendaSessaoLista.Agendas.filter(
      (x) =>
        x.IdAgentePublicoNavigation.Nome +
          ' - ' +
          x.IdAgentePublicoNavigation.IdOrgaoNavigation.Nome ==
        autoridade
    ).length;
  }
  pesquisar(event: any) {
    const query = event.target.value.toLowerCase();
    this.agendaSessaoLista.Agendas = this.agendaSessaoListaBkp.Agendas.filter(
      (item) => {
        return (
          item.Titulo.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.Local.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.Descricao?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.IdAgentePublicoNavigation.Nome.toLowerCase().indexOf(
            query.toLowerCase()
          ) > -1
        );
      }
    );
  }
  fecharCalendario() {
    // this.filtro = [];
    // this.itensSelecionados = [];
    // this.carregarAgenda();
    this.exibeCalendario = false;
    this.modalDatetime.nativeElement.dismiss();
  }
  dataCalendarioChange(event) {
    this.dataSelecionadaStr = event.detail.value;
    if (!this.dataSelecionadaStr) {
      this.fecharCalendario();
      return;
    }
    if (this.itensSelecionados.length == 0) this.carregarAgenda();
    this.carregarCalendarioUi();
    this.fecharCalendario();
  }
  carregarAgenda() {
    // this.exibirLoading('');
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    // this.agendaSessaoService.obterTexto(date.split('T')[0]).then((data) => {
    //   this.textoAgenda = data;
    //   // this.ocultarLoading();
    // });
    tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    var funcao =
      this.itensSelecionados.length == 0
        ? this.agendaAgentePublicoService.listar(date.split('T')[0])
        : new Promise(async (resolve) => {
            var promiseList = [];
            this.itensSelecionados.forEach((item) => {
              var promise1 =
                this.agendaAgentePublicoService.listarPorAutoridade(item);
              promiseList.push(promise1);
            });
            Promise.all(promiseList).then((values) => {
              if (values.length == 0) resolve([]);
              var result = [];
              values.forEach((element) => {
                result = result.concat(element.Agendas);
              });
              values[0].Agendas = result;
              resolve(values[0]);
            });
          });

    this.agendaAgentePublicoService.listarLocais().then((data) => {
      this.locaisLista = data;
      this.locaisListaBkp = data;
    });
    this.exibirSpinning = true;
    funcao.then((data: any) => {
      setTimeout(() => {
        this.exibirSpinning = false;
      }, 3000);
      data.Agendas = data.Agendas.filter(
        (x) => x.IdAgentePublicoNavigation.IdOrgaoNavigation.IdPaiOrigem == null
      );
      this.agendaSessaoLista = data;

      this.agendaSessaoListaBkp = Object.assign({}, data);
      if (this.itensSelecionados.length > 0) {
        this.openDatePicker();
      }
      // this.ocultarLoading();
    });
  }

  diaChanged(ev: any) {
    this.dataSelecionada.setDate(
      this.dataSelecionada.getDate() -
        (this.indexDiaAtual - parseInt(ev.detail.value))
    );
    this.indexDiaAtual = ev.detail.value;
    this.carregarAgenda();
  }
}
