import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe', standalone: false })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'groupBy',
  standalone: false,
})
export class GroupPipe implements PipeTransform {
  transform(collection: Array<any>, property: string): Array<any> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map((key) => ({
      key,
      value: groupedCollection[key],
    }));
  }
}

@Pipe({
  name: 'orderByGrouped',
  standalone: false,
})
export class OrderByGroupedPipe implements PipeTransform {
  transform(collection: Array<any>, property: string): Array<any> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection || collection.length === 0 || !collection[0].value) {
      return null;
    }
    const retorno = new Array<any>();
    collection.forEach((item, index) => {
      item.value.sort((a, b) => {
        return b[property] - a[property];
      });
      retorno.push(item.value[0]);
    });

    // this will return an array of objects, each object containing a group of objects
    return retorno;
  }
}
