import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { ProjetoService } from '../api/projeto.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { TwitterService } from '../api/twitter.service';
import { ParlamentarService } from '../api/parlamentar.service';
import { DiarioOficialService } from '../api/diario-oficial.service';
import { SessaoPlenarioService } from '../api/sessao-plenario.service';
import { VotoParlamentarPage } from '../voto-parlamentar/voto-parlamentar.page';
import { NoticiaService } from '../api/noticia.service';
import { AssinaturaService } from '../api/assinatura.service';
import {
  ChartReadyEvent,
  ChartSelectEvent,
  GoogleChartInterface,
  GoogleChartType,
} from 'ng2-google-charts';
import { DiscursoService } from '../api/discurso.service';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { Share } from '@capacitor/share';
import { OrdemDiaService } from '../api/ordem-dia.service';
import { CongressoEuaService } from '../api/congresso-eua.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { DocumentoProjetoService } from '../api/documento-projeto.service';
import { AgendaSessaoService } from '../api/agenda-sessao.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ComissaoService } from '../api/comissao.service';
import { ComissaoPage } from '../comissao/comissao.page';
import { MovimentacaoService } from '../api/movimentacao.service';
import { AgendaPage } from '../agenda/agenda.page';
import { NewsletterService } from '../api/newsletter.service';

@Component({
  selector: 'app-radar-edicao',
  templateUrl: './radar-edicao.page.html',
  standalone: false,
  styleUrls: ['./radar-edicao.page.scss'],
})
export class RadarEdicaoPage
  extends PageBaseService
  implements OnInit, OnChanges
{
  public columnChart1: GoogleChartInterface;
  public columnChart2: GoogleChartInterface;
  public columnChart3: GoogleChartInterface;
  // public columnChart4: GoogleChartInterface;
  public columnChart5: GoogleChartInterface;
  public columnChart6: GoogleChartInterface;
  public columnChart7: GoogleChartInterface;
  public columnChart8: GoogleChartInterface;
  public columnChart9: GoogleChartInterface;
  public columnChart10: GoogleChartInterface;
  public columnChart11: GoogleChartInterface;
  public columnChart12: GoogleChartInterface;
  public columnChart13: GoogleChartInterface;
  public columnChart14: GoogleChartInterface;
  public columnChart15: GoogleChartInterface;
  public columnChart16: GoogleChartInterface;
  popoverTemaIsOpen = false;
  popoverTituloIsOpen = false;
  popoverCasaIsOpen = false;
  exibePopover = true;
  @ViewChild('popoverTema')
  popoverTema: HTMLIonPopoverElement | undefined;
  @ViewChild('casaLegislativa')
  casaLegislativa: HTMLIonPopoverElement | undefined;
  @ViewChild('popoverTitulo')
  popoverTitulo: HTMLIonPopoverElement | undefined;
  @ViewChild('assunto')
  temaRef: HTMLIonSearchbarElement | undefined;
  @ViewChild('tituloRadar')
  tituloRadarRef: HTMLIonSearchbarElement | undefined;
  @ViewChild('casaLegislativa')
  casaLegislativaRef: HTMLIonTextElement | undefined;
  @Input()
  radar: any;
  // @Input()
  // radarLista: any;
  private _radarLista; // private property _item
  filtroDataApresentacaoTxt: string;
  listaProposicoesBkp: any[];
  carregandoAutoComplete: any;
  autoCompleteProjetoRadarListaBkp: any[];
  autoCompleteProjetoRadarLista: any;
  isItemAvailableProjetoRadar: boolean;
  autoCompleteProjetoRadarTermo: any;
  tagsBkp: any;
  radarProjeto: boolean = false;
  listaProposicoesRadarProjeto: any;
  somenteLeitura: any;
  listaProposicoesIgnoradas = [];
  modal: HTMLIonModalElement;
  stakeholderList: any[];

  // use getter setter to define the property
  get radarLista(): any {
    return this._radarLista;
  }

  @Input()
  set radarLista(val: any) {
    this.listaProposicoesPaginacao = undefined;
    this._radarLista = val;
    this.ngOnInit();
  }
  @Input()
  tendencia: any;
  @Input()
  autor: any;
  listaProposicoes: any[];
  listaProposicoesPaginacao: any[];
  listaTweetsPaginacao: any[];
  qtdRegistrosPagina = 10;
  width = this.platform.width() as any;
  paginaAtual = this.qtdRegistrosPagina;
  carregado = false;
  assuntoFocusFlag = false;
  autorFocusFlag = false;
  segmento = 'proposicoes';
  listaTweets: any[];
  listaAgendaSessao: any[];
  listaAgendaSessaoPaginacao: any[];
  stakeholderLista: any[];
  diarioLista = [];
  diarioListaBkp = [];
  sessaoPlenarioLista: any[];
  noticiaLista: any[];
  insights: any;
  ufAutorLista: any[][];
  temaLista: any[][];
  situacaoLista: any[][];
  posicaoLista: any[][];
  arrayEvolucaoMovimentacao: any[][];
  arrayTempoOrgao: any;
  arrayEvolucaoVotacaoAprovada: any[][];
  arrayEvolucaoVotacaoNaoAprovada: any[][];
  autoCompleteProposicaoListaBkp: any[];
  autoCompleteProposicaoLista: any[];
  autoCompleteAutorListaBkp: any[];
  autoCompleteAutorLista: any[];
  isItemAvailableAutor: boolean;
  isItemAvailableProposicao: boolean;
  carregandoDOU = false;
  carregandoInsights = true;
  carregandoDiscurso = true;
  carregandoTweets = true;
  carregandoAgendaSessao = false;
  carregandoNoticia = true;
  carregandoDocumento = true;
  carregandoOrdemDia = true;
  carregandoProposicao = false;
  discursoLista = [];
  filtroSecaoEstado = true;
  filtroSecaoDou = true;
  filtroOrgaoDou = true;
  diarioFiltroEstadoLista: any;
  diarioFiltroLista: any;
  diarioOrgaoFiltroLista: any;
  exibirSpinning = false;
  ordemDiaLista = [];
  documentoLista = [];
  congressoEuaLista = [];
  diarioListaPaginacao: any[];
  paginaAtualDiario = this.qtdRegistrosPagina;
  paginaAtualAgendaSessao = this.qtdRegistrosPagina;
  paginaAtualTweets = this.qtdRegistrosPagina;
  filtroAssunto = false;
  arrayEvolucaoDiario: any[][];
  exibeProgressoDiario = true;
  tagsPopDou: any[];
  tagsPopDiscurso: any[];
  tagsPopOrdemDia: any[];
  tagsPopDocumento: any[];
  plano: any = {};
  pregoesLista: any[];
  stakeholderAutorLista: any[];
  stakeholderProximidadeLista: any[];
  stakeholderRelatorLista: any[];
  stakeholderAutorDocumentoLista: any[];
  listaDicionarioComissao: any;
  arrayEvolucaoDocumento: any;
  arrayEvolucaoNovaMateria: any;
  arrayEvolucaoTwitter: any;
  arrayEvolucaoAgenda: any;
  arrayEvolucaoNoticia: any;
  arrayEvolucaoDocumentoTag: any;
  arrayBolhaMateria: any;
  constructor(
    private diarioOficialService: DiarioOficialService,
    private parlamentarService: ParlamentarService,
    private twitterService: TwitterService,
    private projetoService: ProjetoService,
    private usuarioService: UsuarioService,
    private modalController: ModalController,
    private radarMonitoramentoService: RadarMonitoramentoService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    private sessaoPlenarioService: SessaoPlenarioService,
    private assinaturaService: AssinaturaService,
    private noticiaService: NoticiaService,
    private discursoService: DiscursoService,
    private ordemDiaService: OrdemDiaService,
    private congressoEuaService: CongressoEuaService,
    private documentoService: DocumentoProjetoService,
    private comissaoService: ComissaoService,
    private agendaSessaoService: AgendaSessaoService,
    private movimentacaoService: MovimentacaoService,
    private newsletterService: NewsletterService,
    private zone: NgZone,
    protected platform: Platform,
    protected actionSheetController: ActionSheetController,
    private vibration: Vibration,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    super(loadingController, alertCtrl, null, null);
  }
  exibirTooltip() {
    if (this.radarLista) {
      return;
    }
    var that = this;
    setTimeout(() => {
      that.popoverTituloIsOpen = true;
      (that.tituloRadarRef as any).el.click();
    }, 1000);
    setTimeout(() => {
      that.popoverTituloIsOpen = false;
      that.popoverTemaIsOpen = true;
      (that.temaRef as any).el.click();
      setTimeout(() => {
        that.popoverTemaIsOpen = false;
        that.popoverCasaIsOpen = true;
        (that.casaLegislativaRef as any).nativeElement.click();
        setTimeout(() => {
          that.popoverCasaIsOpen = false;
          that.exibePopover = false;
        }, 5000);
      }, 5000);
    }, 5000);
  }
  ngOnInit() {
    var usuario = this.usuarioService.get();
    if (!usuario) {
      window.location.href = '/#/primeiro-acesso';
      window.location.reload();
    }
    this.usuarioService.permissao(usuario.Email).then((data) => {
      this.somenteLeitura = data;
    });
    if (
      !this.listaProposicoesPaginacao ||
      this.listaProposicoesPaginacao.length == 0
    ) {
      if (!this.radar || !this.radar.Titulo) {
        this.radar = {
          Camara: true,
          Senado: true,
          DOEDF: false,
          DOESP: false,
          DOERS: false,
          DOERJ: false,
          DOEBA: false,
          DOEAL: false,
          DOEGO: false,
          DOEMS: false,
          DOEMT: false,
          DOEMG: false,
          DOEPR: false,
          DOERO: false,
          DOMUNSP: false,
          DOU: true,
          EUA: false,
          Autor: [],
          Tags: [],
          AutorItem: '',
          AssuntoItem: '',
          IdUsuario: this.usuarioService.get().Id,
        };
        this.exibirTooltip();
      }
      if (this.tendencia) {
        this.radar.AssuntoItem = this.tendencia;
      }
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((data) => {
          this.plano = data;
        });
      this.carregarItem();
      this.carregarAutoComplete();
      this.carregarDicionarioComissao();
    }
  }
  onImgErrorNoticia(item) {
    item.UrlImagem =
      'https://legislapp.com.br/wp-content/uploads/Prancheta-289.png';
  }

  async parlamentarClick(parlamentar) {
    this.exibirLoading('Carregando...');
    this.parlamentarService.obter({ Id: parlamentar.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ParlamentarComponent,
        cssClass: 'modal-pontuacao',
        componentProps: {
          parlamentar: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  fomatarSumario(sumario) {
    return sumario.replace('&nbsp;', '\r\n');
  }
  carregarAutoComplete() {
    if (!this.carregandoAutoComplete && !this._radarLista) {
      this.carregandoAutoComplete = true;
      this.radarMonitoramentoService
        .listarTagsProposicao()
        .then(async (data: any[]) => {
          this.autoCompleteProposicaoLista = data;
          this.autoCompleteProposicaoListaBkp = data;
        });

      this.radarMonitoramentoService
        .listarTagsParlamentar()
        .then(async (data: any[]) => {
          this.autoCompleteAutorLista = data;
          this.autoCompleteAutorListaBkp = data;
        });
    }
  }

  carregarDicionarioComissao() {
    this.comissaoService.listarDicionario().then(async (data: any[]) => {
      this.listaDicionarioComissao = data;
      this.setupMateria();
    });
  }

  autoCompleteAutorClick(termo) {
    this.criarLoading('Carregando...');
    this.radar.AutorItem = termo;
    this.preVisualizar();
    this.zone.run(async () => {
      setTimeout((x) => {
        this.ocultarLoading();
        this.autoCompleteAutorLista = [];
        this.isItemAvailableAutor = false;
      }, 1500);
    });
  }

  autoCompleteProposicaoClick(termo) {
    this.criarLoading('Carregando...');
    this.radar.AssuntoItem = termo;
    this.preVisualizar();
    this.zone.run(async () => {
      setTimeout((x) => {
        this.ocultarLoading();
        this.autoCompleteProposicaoLista = [];
        this.isItemAvailableAutor = false;
      }, 1500);
    });
  }

  getItemAutocompleteProposicao(ev: any) {
    if (
      ev.target.value.length >= 3 &&
      this.autoCompleteProposicaoListaBkp &&
      this.autoCompleteProposicaoListaBkp.length > 0
    ) {
      // Reset items back to all of the items
      this.autoCompleteProposicaoLista = this.autoCompleteProposicaoListaBkp;

      // set val to the value of the searchbar
      const val = ev.target.value;

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
        this.isItemAvailableProposicao = true;
        this.autoCompleteProposicaoLista = this.autoCompleteProposicaoLista
          .filter((item) => {
            return (
              item.toLowerCase().substring(0, ev.target.value.length) ==
              val.toLowerCase()
            );
          })
          .slice(0, 3);
      } else {
        this.isItemAvailableProposicao = false;
      }
    } else {
      this.autoCompleteProposicaoLista = [];
      this.isItemAvailableProposicao = false;
    }
  }
  getItemAutocompleteAutor(ev: any) {
    if (
      ev.target.value.length >= 3 &&
      this.autoCompleteAutorListaBkp &&
      this.autoCompleteAutorListaBkp.length > 0
    ) {
      // Reset items back to all of the items
      this.autoCompleteAutorLista = this.autoCompleteAutorListaBkp;

      // set val to the value of the searchbar
      const val = ev.target.value;

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
        this.isItemAvailableAutor = true;
        this.autoCompleteAutorLista = this.autoCompleteAutorLista
          .filter((item) => {
            return (
              item.toLowerCase().substring(0, ev.target.value.length) ==
              val.toLowerCase()
            );
          })
          .slice(0, 3);
      } else {
        this.isItemAvailableAutor = false;
      }
    } else {
      this.autoCompleteAutorLista = [];
      this.isItemAvailableAutor = false;
    }
  }
  adicionarProposicaoIgnorada(proposicaoIgnorada) {
    if (
      confirm(
        'Se você ignorar esta proposição, ela não será mais exibida no radar. Deseja continuar?'
      )
    ) {
      this.exibirSpinning = true;
      this.projetoService
        .inserirProposicaoIgnorada({
          IdRadar: this.radar.Id,
          IdProposicao: proposicaoIgnorada.Id,
          IdUsuario: this.usuarioService.get().Id,
        })
        .then(async (data) => {
          this.exibirSpinning = false;

          this.listaProposicoesBkp = this.listaProposicoesBkp.filter(
            (x) => x.Id != proposicaoIgnorada.Id
          );

          this.listaProposicoes = this.listaProposicoes.filter(
            (x) => x.Id != proposicaoIgnorada.Id
          );
          this.listaProposicoesPaginacao =
            this.listaProposicoesPaginacao.filter(
              (x) => x.Id != proposicaoIgnorada.Id
            );
        });
      this.listaProposicoesIgnoradas.push(proposicaoIgnorada);
    }
  }
  getItemAutocompleteProjetoRadar(ev: any) {
    const val = ev.target.value;
    if (
      ev.target.value.length >= 5 &&
      this.autoCompleteProjetoRadarListaBkp &&
      this.autoCompleteProjetoRadarListaBkp.length > 0
    ) {
      // Reset items back to all of the items
      this.autoCompleteProjetoRadarLista =
        this.autoCompleteProjetoRadarListaBkp;

      // set val to the value of the searchbar

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
        this.isItemAvailableProjetoRadar = true;
        this.autoCompleteProjetoRadarLista = this.autoCompleteProjetoRadarLista
          .filter((item) => {
            return (
              item.Titulo.toLowerCase().substring(0, ev.target.value.length) ==
              val.toLowerCase()
            );
          })
          .slice(0, 3);
      }
    } else {
      this.autoCompleteProjetoRadarLista = [];
      this.isItemAvailableProjetoRadar = false;
    }
    this.autoCompleteProjetoRadarTermo = val;
  }
  async autoCompleteProjetoRadarClick(proposicao) {
    if (proposicao) {
      const actionSheet = await this.actionSheetController.create({
        // header: '📅',
        cssClass: '',
        buttons: [
          {
            text: 'Visualizar proposição',
            handler: () => {
              this.proposicaoClick(proposicao);
            },
          },
          {
            text: 'Excluir proposição do radar',
            role: 'destructive',
            icon: 'trash',
            handler: () => {
              // this.exibirLoading('Carregando...');
              if (this.radar.Id) {
                this.exibirSpinning = true;
                this.radarMonitoramentoService
                  .ExcluirProjeto(this.radar.Id, proposicao.Id)
                  .then(async (data) => {
                    this.exibirSpinning = false;
                    this.isItemAvailableProjetoRadar = false;
                    this.autoCompleteProjetoRadarLista = [];
                    this.autoCompleteProjetoRadarListaBkp = [];
                    this.radar.FiltroProjetoRadar = '';
                    this.listaProposicoes = undefined;
                    this.listaProposicoesPaginacao = undefined;
                    await this.preVisualizar();
                  });
              } else {
                this.isItemAvailableProjetoRadar = false;
                this.autoCompleteProjetoRadarLista = [];
                this.autoCompleteProjetoRadarListaBkp = [];
                this.radar.FiltroProjetoRadar = '';
                this.listaProposicoes = this.listaProposicoes.filter(
                  (x) => x.Id != proposicao.Id
                );
                this.listaProposicoesPaginacao = this.listaProposicoes.filter(
                  (x) => x.Id != proposicao.Id
                );
              }
            },
          },
          {
            text: 'Cancelar',
            icon: 'close',
            role: 'cancel',
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    } else {
      this.exibirLoading('Carregando...');
      var categoriaLista = [];
      if (this.radar.Camara) {
        categoriaLista.push('96FCAB03-6F60-43B7-9704-D4FEA1125D7E');
      }

      if (this.radar.Senado) {
        categoriaLista.push('96FCAB03-6F60-43B7-9704-D4FEA1115D7E');
      }

      categoriaLista.forEach((categoria) => {
        var filtro = {
          IdCategoria: categoria,
          tituloLista: this.autoCompleteProjetoRadarTermo.split(','),
        };

        this.projetoService
          .listarPorTituloECasa(filtro)
          .then(async (data: any) => {
            if (data.length > 0) {
              if (this.radar.Id) {
                this.autoCompleteProjetoRadarLista = [];
                this.autoCompleteProjetoRadarListaBkp = [];
                this.radar.FiltroProjetoRadar = '';
                // ...

                this.listaProposicoes = undefined;
                this.listaProposicoesPaginacao = undefined;
                this.isItemAvailableProjetoRadar = false;
                this.exibirSpinning = true;
                for (const projeto of data) {
                  await this.radarMonitoramentoService.InserirProjeto(
                    this.radar.Id,
                    projeto.Id,
                    false
                  );
                  const toast = await this.toastController.create({
                    message: `Projeto ${projeto.Titulo} adicionado ao radar`,
                    duration: 5000,
                    color: 'success',
                  });
                  toast.present();
                }
                this.radarMonitoramentoService
                  .Indexar(this.radar.Id)
                  .then(async (data) => {});
                this.exibirSpinning = false;
                this.loading.dismiss();
                setTimeout(() => {
                  this.preVisualizar();
                }, 3000);
              } else {
                if (!this.listaProposicoesRadarProjeto)
                  this.listaProposicoesRadarProjeto = [];
                this.listaProposicoesRadarProjeto = [
                  ...this.listaProposicoesRadarProjeto,
                  ...data,
                ];
                this.autoCompleteProjetoRadarListaBkp = data;
                this.radar.FiltroProjetoRadar = '';
                this.isItemAvailableProjetoRadar = false;
                this.listaProposicoes = this.listaProposicoesRadarProjeto;
                this.segmentoChange({ detail: { value: 'proposicoes' } });
                this.ocultarLoading();
              }
            } else {
              console.log(
                'Proposição não encontrada: ' +
                  this.autoCompleteProjetoRadarTermo
              );
              this.ocultarLoading();
            }
          });
      });
    }
  }
  noticiaClick(noticia) {
    window.open(noticia.Url, '_system');
  }
  getQtdProposicaoTag(tag) {
    try {
      return (
        this.listaProposicoes.filter((x) => x.Tags == tag).length +
        (this.radar.DOU
          ? this.diarioListaBkp.filter((x) => x.Tag == tag).length
          : 0) +
        this.congressoEuaLista.filter((x) => x.Tags == tag).length +
        this.listaTweets.filter((x) => x.Tags == tag).length +
        this.noticiaLista.filter((x) => x.Tags == tag).length
      );
    } catch (error) {
      return 0;
    }
  }

  carregarListaVotacao(projetoLista: any[]) {
    this.sessaoPlenarioService
      .listarPorProjetoLista(projetoLista.map((x) => x.Id))
      .then((data: any[]) => {
        this.sessaoPlenarioLista = data;
        this.sessaoPlenarioLista.map((item) => {
          var projeto = projetoLista.find((x) => x.Id == item.IdProjeto);
          item.Tag = projeto.Tags;
        });
      });
  }
  carregarListaDiscurso(projetoLista: any[]) {
    var idProjetoLista = this.listaProposicoes.map((x) => x.Id);
    this.discursoService.listarPorProjeto(idProjetoLista).then((data) => {
      this.discursoLista = this.discursoLista
        .concat(data as any[])
        .sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            (new Date(b.Discurso.Data) as any) -
            (new Date(a.Discurso.Data) as any)
          );
        });
    });
    this.tagsPopDiscurso = [...this.radar.Tags, ...[this.radar.Titulo]];
    this.carregarDiscursoRecursivo();
  }

  carregarListaDocumento() {
    var idProjetoLista = this.listaProposicoes.map((x) => x.Id).slice(0, 200);
    this.documentoService
      .listarRecentesPorProjeto(idProjetoLista)
      .then((data: any) => {
        this.documentoLista = this.documentoLista
          .concat(data as any[])
          .map((doc) => {
            if (this.radar.Tags && this.radar.Tags.length > 0) {
              doc = this.radar.Tags.map((tag) => {
                tag = tag.toUpperCase();
                doc.Texto = doc.Texto.replace(
                  tag,
                  `<mark><b>${tag}<b/></mark>`
                );
                return doc;
              }).pop();
            }
            doc.Projeto = this.listaProposicoes.filter(
              (x) => x.Id == doc.DocumentoProjeto.IdProjeto
            )[0];
            return doc;
          })
          .sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              (new Date(b.DocumentoProjeto.Data) as any) -
              (new Date(a.DocumentoProjeto.Data) as any)
            );
          });
        this.tagsPopDocumento = [...this.radar.Tags];
        if (this.tagsPopDocumento.length == 0) {
          this.carregandoDocumento = false;
        } else this.carregarDocumentoRecursivo();
      });
  }
  carregarDiscursoRecursivo() {
    var tag = this.tagsPopDiscurso.pop();
    this.discursoService
      .listarPorTag(tag)
      .then((data: any[]) => {
        // data = data.map(x => { x.Tag = tag; return x; });
        this.discursoLista = this.discursoLista
          .concat(data as any[])
          .sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              (new Date(b.Discurso.Data) as any) -
              (new Date(a.Discurso.Data) as any)
            );
          });
      })
      .finally(() => {
        if (this.tagsPopDiscurso.length > 0) {
          this.carregarDiscursoRecursivo();
        } else {
          this.carregandoDiscurso = false;
        }
      });
  }
  carregarDocumentoRecursivo() {
    var tag = this.tagsPopDocumento.pop();
    var idRadar = this.radar && this.radar.Id ? this.radar.Id : '';
    if (this._radarLista && this._radarLista.length > 0) {
      var radar = this._radarLista.filter(
        (x) => x.Tags && x.Tags.includes(tag)
      );
      if (radar && radar.length > 0) idRadar = radar[0].Id;
    }
    this.documentoService
      .listarPorTag(tag, idRadar)
      .then((data: any[]) => {
        // data = data.map(x => { x.Tag = tag; return x; });
        this.documentoLista = this.documentoLista
          .concat(data as any[])
          .sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              (new Date(b.DocumentoProjeto.Data) as any) -
              (new Date(a.DocumentoProjeto.Data) as any)
            );
          });
      })
      .finally(() => {
        if (
          this.tagsPopDocumento.length > 0 &&
          !this.exibirSpinning &&
          this.documentoLista.length < 1000
        ) {
          this.carregarDocumentoRecursivo();
        } else {
          this.carregandoDocumento = false;
        }
      });
  }
  carregarListaNoticia(projetoLista: any[]) {
    this.noticiaService
      .listarPorProjeto({
        IdProjetoLista: [],
        Tags: [
          ...this.listaProposicoes.map((x) => x.Titulo).slice(0, 20),
          ...this.radar.Tags.slice(0, 20),
          ...[this.radar.Titulo],
        ],
      })
      .then((data: any[]) => {
        this.noticiaLista = data;
        this.carregandoNoticia = false;
      });
  }

  carregarListaOrdemDia() {
    var idRadar = this.radar && this.radar.Id ? this.radar.Id : '';
    var tag = this.tagsPopOrdemDia.pop();
    if (this.radar.Tags.length == 0) {
      var idProjetoLista = this.listaProposicoes.map((x) => x.Id);
      var funcAgenda = this.ordemDiaService.listarPorProjetoLista({
        idProjetoLista,
      });
      funcAgenda
        .then((data: any[]) => {
          data = data.map((x) => {
            x.Tag = tag;
            return x;
          });
          this.ordemDiaLista = this.ordemDiaLista
            .concat(data as any[])
            .sort(function (a: any, b: any) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return (new Date(b.Data) as any) - (new Date(a.Data) as any);
            });
        })
        .finally(() => {
          this.carregandoOrdemDia = false;
          this.ordemDiaLista = this.ordemDiaLista.sort(function (
            a: any,
            b: any
          ) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (new Date(b.Data) as any) - (new Date(a.Data) as any);
          });
        });
    } else {
      this.ordemDiaService
        .listarPorTag(tag, idRadar)
        .then((data: any[]) => {
          data = data.map((x) => {
            x.Tag = tag;
            return x;
          });
          this.ordemDiaLista = this.ordemDiaLista
            .concat(data as any[])
            .sort(function (a: any, b: any) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return (new Date(b.Data) as any) - (new Date(a.Data) as any);
            });
        })
        .finally(() => {
          if (this.tagsPopOrdemDia.length > 0 && !this.exibirSpinning) {
            this.carregarListaOrdemDia();
          } else {
            this.carregandoOrdemDia = false;
            this.ordemDiaLista = this.ordemDiaLista.sort(function (
              a: any,
              b: any
            ) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return (new Date(b.Data) as any) - (new Date(a.Data) as any);
            });
          }
        });
    }
  }

  carregarListaCongressoEua(projetoLista: any[]) {
    if (this.radar.EUA) {
      this.congressoEuaLista = [];
      var tags = [...this.radar.Tags]
        .filter((x) => x.indexOf(' ') == -1)
        .filter((x) => x);
      var that = this;
      if (tags.length == 0) return;
      var interval = setInterval(() => {
        // var projeto = projetoLista.pop();
        // that.ordemDiaService
        //   .listarPorTag(projeto.Titulo)
        //   .then((data: any[]) => {
        //     that.ordemDiaLista = that.ordemDiaLista.concat(data as any[]);
        //   });
        var tag = tags.pop();
        that.congressoEuaService.listarPorTag(tag).then((data: any) => {
          if (data.results[0].bills.length > 0) {
            var result = data.results[0].bills as any[];
            result = result.map((x) => {
              x.Tag = tag;
              return x;
            });
            that.congressoEuaLista = that.congressoEuaLista
              .concat(result)
              .sort(function (a: any, b: any) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return (
                  (new Date(b.latest_major_action_date) as any) -
                  (new Date(a.latest_major_action_date) as any)
                );
              });
          }
          if (tags.length == 0) clearInterval(interval);
        });
      }, 1000);
    }
  }
  carregarInsights() {
    if (this.radar) {
      var radar = {
        Id: String,
        Autores: String,
        Tags: String,
        Titulo: String,
        Camara: String,
        Senado: String,
        DOEAL: String,
        DOEBA: String,
        DOESP: String,
        DOERJ: String,
        DOMUNSP: String,
        DOERS: String,
        DOEDF: String,
        IdUsuario: String,
      };
      radar.Autores = this.radar.Autor.join(',');
      radar.Tags = this.radar.Tags.join(',');
      radar.Titulo = this.radar.Titulo;
      radar.Camara = this.radar.Camara;
      radar.Senado = this.radar.Senado;
      radar.DOESP = this.radar.DOESP;
      radar.DOERJ = this.radar.DOERJ;
      radar.DOEBA = this.radar.DOEBA;
      radar.DOEAL = this.radar.DOEAL;
      radar.DOERS = this.radar.DOERS;
      radar.DOEDF = this.radar.DOEDF;
      radar.DOMUNSP = this.radar.DOMUNSP;
      radar.Id = this.radar.Id;
      radar.IdUsuario = this.radar.IdUsuario;
      this.radarMonitoramentoService.insights(radar).then((data: any) => {
        this.insights = data;
      });
    }
  }
  ready(event: ChartReadyEvent) {
    // your logic
  }
  bolhaClick(event: ChartSelectEvent) {
    var Id = event.selectedRowValues[5];
    this.proposicaoClick({ Id });
  }
  carregarGraficoEstado(data) {
    this.ufAutorLista = (data as any[]).map((x) => [
      x.Tema.toString(),
      x.Qtd as number,
    ]);
    var width =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.3;
    var chartWidth =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.4;
    this.ufAutorLista.unshift(['Mês/Ano', 'Estado da autoria']);
    this.columnChart1 = {
      chartType: GoogleChartType.ColumnChart,
      dataTable: this.ufAutorLista,
      options: {
        allowHtml: true,
        width,
        height: 250,
        chartArea: { width: chartWidth, height: '200' },
        legend: { position: 'none' },
      },
    };
  }
  carregarGraficoTema(data) {
    this.temaLista = (data as any[]).map((x) => [
      x.Tema.toString(),
      x.Qtd as number,
    ]);
    var width =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.3;
    var chartWidth =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.4;
    this.temaLista.unshift(['Quantidade', 'Proposições']);
    this.columnChart11 = {
      chartType: GoogleChartType.BarChart,
      dataTable: this.temaLista,
      options: {
        allowHtml: true,
        width,
        height: 350,
        chartArea: { width: chartWidth, height: '300' },
        vAxis: { textPosition: 'in' },
        legend: { position: 'none' },
      },
    };
  }

  carregarGraficoPartido(data) {
    this.situacaoLista = (data as any[]).map((x) => [
      x.Tema.toString(),
      x.Qtd as number,
    ]);
    this.situacaoLista.unshift(['Mês/Ano', 'Novas matérias']);
    var width =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.1;
    var chartWidth =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.3;
    this.columnChart3 = {
      chartType: GoogleChartType.PieChart,
      dataTable: this.situacaoLista,
      options: {
        allowHtml: true,
        pieHole: 0.4,
        width: width,
        height: 250,
        chartArea: { width: chartWidth, height: '200' },
        legend: { position: 'right' },
      },
    };
  }

  carregarGraficoPosicionamento(data) {
    this.posicaoLista = (data as any[]).map((x) => [
      x.Tema.toString(),
      x.Qtd as number,
    ]);
    this.posicaoLista.unshift(['Mês/Ano', 'Posicionamento']);
    var width =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.1;
    var chartWidth =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.3;
    this.columnChart15 = {
      chartType: GoogleChartType.PieChart,
      dataTable: this.posicaoLista,
      options: {
        allowHtml: true,
        pieHole: 0.4,
        width: width,
        height: 250,
        chartArea: { width: chartWidth, height: '200' },
        legend: { position: 'right' },
      },
    };
  }

  carregarProgressaoProposicao(radar: any) {
    var that = this;
    setTimeout(() => {
      this.carregandoInsights = false;
    }, 10000);
    this.columnChart5 = undefined;
    this.columnChart8 = undefined;
    var radarTemp = { Tags: radar.Tags };
    var promise;
    if (radar.Tags.length > 0) {
      promise = this.radarMonitoramentoService.listarProgressaoMovimentacao(
        this.buildUrl(radarTemp)
      );
    } else {
      var projetoLista = this.listaProposicoes.map((x) => {
        var projeto = { Tags: '', Id: '' };
        projeto.Tags = x.Tags.split('|')[0];
        projeto.Id = x.Id;
        return projeto;
      });
      promise =
        this.radarMonitoramentoService.listarProgressaoMovimentacaoRadarProjeto(
          projetoLista
        );
    }
    this.movimentacaoService
      .ListarTempoOrgaoListaProjeto(this.listaProposicoes.map((x) => x.Id))
      .then((data) => {
        this.arrayTempoOrgao = (data as any[]).map((x) => [
          x.Orgao.toString(),
          x.Dias as number,
        ]);
        this.arrayTempoOrgao.unshift(['Dias', 'Dias no Orgão']);
        var width =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.2;
        var chartWidth =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.4;
        this.columnChart13 = {
          // use :any or :GoogleChartInterface
          chartType: 'BarChart',
          dataTable: this.arrayTempoOrgao,
          options: {
            width,
            height: 300,
            chartArea: { width: chartWidth },
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 10 },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 10 } },
            bar: { groupWidth: '100%' },
            isStacked: true,
            legend: {
              position: 'top',
            },
          },
        };
      });
    promise.then((data: any) => {
      this.arrayEvolucaoMovimentacao = data.Movimentacao;
      this.columnChart5 = {
        chartType: 'ScatterChart',
        dataTable: this.arrayEvolucaoMovimentacao,
        options: {
          width: 900,
          chartArea: { width: '800' },
          vAxis: {
            titleTextStyle: { color: '#069' },
          },
          hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
          lineWidth: 1,
          legend: {
            position: 'in',
          },
        },
      };

      this.arrayEvolucaoNovaMateria = data.NovaMateria;
      this.columnChart14 = {
        chartType: 'ScatterChart',
        dataTable: this.arrayEvolucaoNovaMateria,
        options: {
          width: 900,
          chartArea: { width: '800' },
          vAxis: {
            titleTextStyle: { color: '#069' },
          },
          hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
          lineWidth: 1,
          legend: {
            position: 'in',
          },
        },
      };
    });

    var promise;
    if (radar.Tags.length > 0) {
      promise = this.radarMonitoramentoService.listarProgressaoDocumento(
        this.buildUrl(radarTemp)
      );
    } else {
      var projetoLista = this.listaProposicoes.map((x) => {
        var projeto = { Tags: '', Id: '' };
        projeto.Tags = x.Tags.split('|')[0];
        projeto.Id = x.Id;
        return projeto;
      });
      promise =
        this.radarMonitoramentoService.listarProgressaoDocumentoRadarProjeto(
          projetoLista
        );
    }
    promise.then((data: any) => {
      this.arrayEvolucaoDocumento = data.Documento;
      this.columnChart8 = {
        chartType: 'ScatterChart',
        dataTable: this.arrayEvolucaoDocumento,
        options: {
          width: 900,
          chartArea: { width: '800' },
          vAxis: {
            titleTextStyle: { color: '#069' },
          },
          hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
          lineWidth: 1,
          legend: {
            position: 'in',
          },
        },
      };
    });
    var idProjetoLista = this.listaProposicoes.map((x) => x.Id);
    var funcAgenda =
      radar.Tags.length > 0
        ? this.agendaSessaoService.listarProgressao(this.buildUrl(radar))
        : this.agendaSessaoService.listarProgressaoPorProjetoLista({
            idProjetoLista,
          });
    funcAgenda.then((data: any) => {
      this.arrayEvolucaoAgenda = data.Agenda;
      this.columnChart12 = {
        chartType: 'ScatterChart',
        dataTable: this.arrayEvolucaoAgenda,
        options: {
          width: 900,
          chartArea: { width: '800' },
          vAxis: {
            titleTextStyle: { color: '#069' },
          },
          hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
          lineWidth: 1,
          legend: {
            position: 'in',
          },
        },
      };
    });
    if (radarTemp.Tags.length > 0) {
      this.radarMonitoramentoService
        .listarProgressaoTwitter(this.buildUrl(radarTemp))
        .then((data: any) => {
          this.arrayEvolucaoTwitter = data.Twitter;
          this.columnChart9 = {
            chartType: 'ScatterChart',
            dataTable: this.arrayEvolucaoTwitter,
            options: {
              width: 900,
              chartArea: { width: '800' },
              vAxis: {
                titleTextStyle: { color: '#069' },
              },
              hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
              lineWidth: 1,
              legend: {
                position: 'in',
              },
            },
          };
        });
    }

    var promise;
    if (radar.Tags.length > 0) {
      promise = this.radarMonitoramentoService.listarProgressaoNoticia(
        this.buildUrl(radarTemp)
      );
    } else {
      var projetoLista = this.listaProposicoes.map((x) => {
        var projeto = { Tags: '', Id: '' };
        projeto.Tags = x.Tags.split('|')[0];
        projeto.Id = x.Id;
        return projeto;
      });
      promise =
        this.radarMonitoramentoService.listarProgressaoNoticiaRadarProjeto(
          projetoLista
        );
    }
    promise.then((data: any) => {
      this.arrayEvolucaoNoticia = data.Noticia;
      this.columnChart10 = {
        chartType: 'ScatterChart',
        dataTable: this.arrayEvolucaoNoticia,
        options: {
          width: 900,
          chartArea: { width: '800' },
          vAxis: {
            titleTextStyle: { color: '#069' },
          },
          hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
          lineWidth: 1,
          legend: {
            position: 'in',
          },
        },
      };
    });
  }
  carregarProgressaoVotacao(radar: any) {
    this.sessaoPlenarioService
      .listarProgressoAprovada(this.listaProposicoes.map((x) => x.Id))
      .then((data) => {
        this.arrayEvolucaoVotacaoAprovada = (data as any[]).map((x) => [
          x.Key.toString(),
          x.Value as number,
          x.Value2 as number,
        ]);
        var width = this.platform.width() as any;
        var chartWidth =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.2;

        this.arrayEvolucaoVotacaoAprovada.unshift([
          'Mês/Ano',
          'Aprovadas',
          'Rejeitadas',
        ]);
        this.columnChart6 = {
          chartType: 'ScatterChart',
          dataTable: this.arrayEvolucaoVotacaoAprovada,
          options: {
            width: 700,
            chartArea: { width: 850 },
            vAxis: {
              title: 'Votações Aprovadas x Rejeitadas',
              titleTextStyle: { color: '#069' },
            },
            hAxis: { title: 'Mês/Ano', titleTextStyle: { color: '#069' } },
            lineWidth: 1,
            legend: {
              position: 'right',
            },
          },
        };
      });
  }
  carregarBolhaProposicao(radar: any) {
    this.projetoService
      .listarBolha(this.listaProposicoes.map((x) => x.Id))
      .then((data) => {
        var arrayBolhaMateria = (data as any[]).map((x) => [
          x.Key.toString(),
          x.Data,
          x.QtdMovimentacoes as number,
          x.Tag,
          x.Tamanho as number,
          x.Id.toString(),
        ]);
        var width = this.platform.width() as any;
        var chartWidth =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.1;

        arrayBolhaMateria.unshift([
          'Proposição',
          'Dia do último status',
          'Qtd Andamentos',
          'Último local',
          'Tamanho',
          'Id',
        ]);

        this.columnChart16 = {
          chartType: 'BubbleChart',
          dataTable: arrayBolhaMateria,
          options: {
            width: 500,
            height: 300,
            bubble: {
              textStyle: {
                fontSize: 10,
              },
            },
            hAxis: {
              title: 'Dia da movimentação',
              viewWindow: {
                min: 0,
                max: 30,
              },
            },
            vAxis: {
              viewWindow: {
                min: 0,
                max: 10,
              },
            },
            chartArea: { width: 400, height: 250 },
            legend: {
              position: 'top',
            },
          },
        };
        this.arrayBolhaMateria = arrayBolhaMateria;
      });
  }

  carregarGraficoSituacao(data) {
    this.situacaoLista = (data as any[]).map((x) => [
      x.Tema.toString(),
      x.Qtd as number,
    ]);

    var width =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.1;
    var chartWidth =
      (this.platform.width() as any) - (this.platform.width() as any) * 0.3;

    this.situacaoLista.unshift(['Mês/Ano', 'Novas matérias']);
    this.columnChart2 = {
      chartType: GoogleChartType.PieChart,
      dataTable: this.situacaoLista,
      options: {
        allowHtml: true,
        width: width,
        height: 250,
        chartArea: { width: chartWidth, height: '200' },
        legend: { position: 'right' },
      },
    };
  }

  segmentoChange($event) {
    if ($event && $event.detail) {
      this.segmento = $event.detail.value;
    }
    if (this.segmento != 'diario') this.exibeProgressoDiario = false;
    if (this.segmento == 'agendaSessao') this.carregarAgendaSessao();
    if (this.segmento == 'tweets') {
      this.exibirLoading('Carregando tweets');
      this.carregarHtmlTweets();
    } else {
      // this.exibeProgressoDiario = true;
      // this.carregarProgressoDiario();
    }

    if (this.segmento == 'insights') {
      this.carregarGraficoEstado(this.insights.Estados);
      this.carregarGraficoTema(this.insights.Tema);
      this.carregarGraficoSituacao(this.insights.Status);
      this.carregarGraficoPartido(this.insights.Partidos);
      this.carregarGraficoPosicionamento(this.insights.Posicao);
      this.carregarProgressaoProposicao(this.radar);
      this.carregarProgressaoVotacao(this.radar);
      this.carregarBolhaProposicao(this.radar);
    }
    // if (this.segmento == 'proposicoes') {
    //   this.carregarProgressaoProposicao(this.radar);
    // }
    this.iniciaInfinite();
  }
  ngOnChanges() {}
  cadastrarNewsletter() {
    this.exibirLoading('Gerando Newsletter...');
    var newsletterEdicao: {} = {};
    newsletterEdicao['IdUsuario'] = this.usuarioService.get().Id;
    newsletterEdicao['EnvioAutomatico'] = true;
    newsletterEdicao['Escopo'] = 'Meus Radares';
    newsletterEdicao['Periodo'] = 'Semanal';
    newsletterEdicao['EmailListaStr'] = this.usuarioService.get().Email;
    this.newsletterService.criar(newsletterEdicao).then(async (data) => {
      this.ocultarLoading();
      this.vibration.vibrate(2000);
      const toast = await this.toastController.create({
        message: '🎉 Newsletter criada com sucesso!',
        duration: 5000,
        color: 'success',
      });
      toast.present();
    });
  }

  async salvarClick() {
    if (this.formularioValido()) {
      if (!this.radar.Titulo) this.radar.Titulo = this.radar.Tags[0];
      if (!this.radar.Titulo) this.radar.Titulo = 'Radar sem título';
      this.exibirLoading('Carregando...');
      const func = this.radar.Id
        ? this.radarMonitoramentoService.alterar(this.radar)
        : this.radarMonitoramentoService.cadastrar(this.radar);
      func.then(async (data: any) => {
        if (this.listaProposicoesRadarProjeto) {
          this.listaProposicoesRadarProjeto.forEach((projeto) => {
            this.radarMonitoramentoService
              .InserirProjeto(data, projeto.Id)
              .then(() => {});
          });
        }
        var that = this;
        setTimeout(async () => {
          const alert = await that.alertController.create({
            header:
              'Deseja receber uma newsletter com atualizações deste monitoramento?',
            message: 'Receba via e-mail, telegram ou whatsapp em PDF',
            buttons: [
              {
                text: 'Não receber',
                role: 'cancel',
                handler: () => {},
              },
              {
                text: 'Receber newsletter',
                role: 'confirm',
                handler: () => {
                  that.cadastrarNewsletter();
                },
              },
            ],
          });
          await alert.present();
        }, 4000);
        that.vibration.vibrate(2000);
        const toast = await that.toastController.create({
          message: '🎉 Radar salvo com sucesso!',
          duration: 6000,
          color: 'success',
        });
        toast.present();
        localStorage.setItem('RADAR_MODIFICADO', 'true');
        try {
          this.zone.run(() => {
            this.usuarioService
              .obterPlano(this.usuarioService.get().Id)
              .then((data) => {
                this.plano = data;
                if (!this.plano) this.assinaturaService.assinar();
              });
          });
        } catch (error) {}
        this.fechar();
        this.ocultarLoading();
      });
    }
  }

  fechar() {
    this.modalController.dismiss();
  }

  assuntoFocus() {
    this.assuntoFocusFlag = true;
  }

  assuntoBlur() {
    this.assuntoFocusFlag = false;
  }

  autorFocus() {
    this.autorFocusFlag = true;
  }

  autorBlur() {
    this.autorFocusFlag = false;
  }
  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  filtrarPorAutor(filtro, scope) {
    if (!filtro) {
      scope.listaProposicoesPaginacao = scope.listaProposicoes;
      scope.iniciaInfinite();
    } else
      scope.listaProposicoesPaginacao = scope.listaProposicoes.filter(
        (x) => x.Autoria == filtro
      );
  }
  setupMateria() {
    if (this.listaProposicoesPaginacao) {
      this.stakeholderList = [];
      var grupoLista = this.groupBy(this.listaProposicoes, 'Autoria');
      for (let index = 0; index < Object.keys(grupoLista).length; index++) {
        var grupo: any = Object.values(grupoLista)[index];
        var proposicao = grupo[0];
        var Imagem =
          proposicao.Autoria == 'Senado Federal'
            ? '/assets/logo-senado.png'
            : proposicao.Autoria == 'Câmara dos Deputados'
            ? '/assets/logo-camara.png'
            : proposicao.UrlFotoAutor;
        this.stakeholderList.push({
          Imagem,
          Id: proposicao.IdAutor,
          Nome: proposicao.Autoria,
          Qtd: grupo.length,
        });
      }

      this.stakeholderList = this.stakeholderList
        .filter(
          (x) => (x.Imagem || x.Id) && x.Imagem != '/assets/imagem-perfil.jpg'
        )
        .sort(function (a, b) {
          return b.Qtd - a.Qtd;
        })
        .slice(0, 20);

      this.listaProposicoesPaginacao.map((proposicao) => {
        if (!proposicao.Status) proposicao.Status = proposicao.UltimaTramitacao;
        proposicao.ExibePrazo =
          proposicao.Status?.toLowerCase().indexOf('prazo') > -1;
        proposicao.ExibeNovo =
          new Date(proposicao.DataUltimoStatus).getTime() >=
          new Date(new Date().toISOString().split('T')[0]).getTime();
        if (this.listaDicionarioComissao) {
          var comissao = this.listaDicionarioComissao.filter(
            (com) => proposicao.Status?.indexOf(`[${com.Value}]`) > -1
          );
          if (comissao.length > 0) proposicao.Comissao = comissao[0];
        }
      });
      this.listaProposicoesPaginacao = this.listaProposicoesPaginacao.sort(
        function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            (new Date(b.DataUltimoStatus) as any) -
            (new Date(a.DataUltimoStatus) as any)
          );
        }
      );
    }
  }

  iniciaInfinite() {
    if (this.listaProposicoes) {
      this.listaProposicoesPaginacao = this.listaProposicoes.slice(
        0,
        this.paginaAtual
      );
      this.setupMateria();
    }
    if (this.listaTweets) {
      this.listaTweetsPaginacao = this.listaTweets.slice(0, this.paginaAtual);
      this.setupMateria();
    }
    if (this.diarioLista) {
      this.diarioListaPaginacao = this.diarioLista.slice(
        0,
        this.paginaAtualDiario
      );
    }
    if (this.listaAgendaSessao) {
      this.listaAgendaSessaoPaginacao = this.listaAgendaSessao.slice(
        0,
        this.paginaAtualAgendaSessao
      );
    }
  }

  async verRadarClick(idRadar) {
    this.modalController.dismiss();
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        var radar = data.find((x) => x.Id == idRadar);
        const modal = await this.modalController.create({
          component: RadarEdicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            radar,
          },
        });
        return await modal.present();
      });
  }

  verComissao(comissao) {
    this.comissaoService.obter({ Id: comissao.Key }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  doInfinite(event) {
    if (this.segmento == 'proposicoes') {
      this.zone.run(() => {
        this.paginaAtual = this.paginaAtual + this.qtdRegistrosPagina;
        this.listaProposicoesPaginacao = this.listaProposicoes.slice(
          0,
          this.paginaAtual
        );
        this.carregarCountProposicoes();
        this.setupMateria();
        if (event) {
          event.target.complete();
        }
      });
    } else if (this.segmento == 'diario') {
      this.exibeProgressoDiario = true;
      this.doInfiniteDiario(event);
    } else if (this.segmento == 'tweets') {
      this.carregandoTweets = true;
      this.doInfiniteTweets(event);
    } else if (this.segmento == 'agendaSessao') {
      this.doInfiniteAgendaSessao(event);
    }
  }

  doInfiniteAgendaSessao(event) {
    this.zone.run(() => {
      this.paginaAtualAgendaSessao =
        this.paginaAtualAgendaSessao + this.qtdRegistrosPagina;
      this.listaAgendaSessaoPaginacao = this.listaAgendaSessao.slice(
        0,
        this.paginaAtualAgendaSessao
      );
      if (event) {
        event.target.complete();
      }
    });
  }

  doInfiniteDiario(event) {
    this.zone.run(() => {
      this.paginaAtualDiario = this.paginaAtualDiario + this.qtdRegistrosPagina;
      this.diarioListaPaginacao = this.diarioLista.slice(
        0,
        this.paginaAtualDiario
      );
      if (event) {
        event.target.complete();
      }
    });
  }
  doInfiniteTweets(event) {
    this.zone.run(() => {
      this.paginaAtualTweets = this.paginaAtualTweets + this.qtdRegistrosPagina;
      this.listaTweetsPaginacao = this.listaTweets.slice(
        0,
        this.paginaAtualTweets
      );
      this.carregarHtmlTweets();
      setTimeout(() => {
        (window as any).twttr.widgets.load();
      }, 3000);
      this.carregandoTweets = false;
      if (event) {
        event.target.complete();
      }
    });
  }

  carregarItem() {
    this.carregado = true;
    this.preVisualizar();
  }

  async proposicaoClick(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: proposicao.Id, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  async votoClick(voto) {
    this.exibirLoading('Carregando...');
    this.sessaoPlenarioService
      .listarPorId(voto.IdSessaoPlenario)
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: VotoParlamentarPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            projetoSessaoPlenario: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }
  onImgError(item) {
    if (item.Autoria == 'Senado Federal') {
      item.UrlFotoAutor = '/assets/logo-senado.png';
    } else if (
      item.Autoria == 'Câmara dos Deputados' ||
      item.Autoria == 'Câmara Dos Deputados'
    ) {
      item.UrlFotoAutor = '/assets/logo-camara.png';
    } else if (
      item.Autoria == 'Poder Executivo' ||
      item.Autoria == 'Presidência da República'
    ) {
      item.UrlFotoAutor = '/assets/poderexecutivo.png';
    } else if (item.Autoria.toUpperCase().indexOf('COMISSÃO') > -1) {
      item.UrlFotoAutor = '/assets/comissoes.png';
    } else {
      item.UrlFotoAutor = '/assets/imagem-perfil.jpg';
    }
    item.UrlFotoParlamentar = item.UrlFotoAutor;
  }

  camaraSenadoChange(prop) {
    // if (!this.carregado) {
    // this.radar[prop] = !this.radar[prop];
    if (this.radar.Tags.length > 0) this.preVisualizar();
    // }
  }

  carregarCountProposicoes() {
    this.projetoService
      .count(
        this.listaProposicoes
          .map((x) => x.Id)
          .slice(
            this.listaProposicoesPaginacao.length - this.paginaAtual,
            this.paginaAtual
          )
      )
      .then((data: any[]) => {
        this.listaProposicoesPaginacao.forEach((proposicao) => {
          proposicao.Count = data.filter(
            (x) => x.IdProjeto == proposicao.Id
          )[0];
          if (proposicao.Count)
            proposicao.Termometro = proposicao.Count.Termometro;
        });
      });
  }
  async agendaClick(agenda) {
    this.agendaSessaoService.obter(agenda.Id).then(async (data) => {
      if (this.plano) {
        const modal = await this.modalController.create({
          component: AgendaPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            agenda: data,
          },
        });
        return await modal.present();
      } else {
        this.assinaturaService
          .inicializar(this.usuarioService.get().Id)
          .subscribe((data) => {
            this.plano = data;
          });
      }
    });
  }
  carregarAgendaSessao() {
    if (this.listaProposicoes && !this.carregandoAgendaSessao) {
      var idProjetoLista = this.listaProposicoes.slice(0, 300).map((x) => x.Id);
      var func;
      if (this.radar.Tags.length > 0) {
        func = this.agendaSessaoService.listarPorTag(
          this.buildUrl({
            tags: [...this.radar.Tags, ...[this.radar.Titulo]],
          })
        );
      } else {
        func = this.agendaSessaoService.listarPorProjetoLista({
          idProjetoLista,
        });
      }
      this.carregandoAgendaSessao = true;
      func.then((data: any) => {
        this.carregandoAgendaSessao = false;
        this.listaAgendaSessao = data;
        this.listaAgendaSessao.map((x) => {
          if (x.UrlVideo) {
            var url =
              'https://www.youtube.com/embed/' + x.UrlVideo.split('?v=')[1];
            x.UrlVideo = url;
          }
        });
      });
    }
  }

  carregarTweets() {
    var model = this.buildUrl({ tags: this.radar.Tags.slice(0, 10) });
    if (this.listaProposicoes.length > 0) {
      this.carregandoTweets;
      this.twitterService
        .buscar(
          this.listaProposicoes.map((x) => x.Id),
          []
        )
        .then((data) => {
          this.listaTweets = data as any[];
          // if (this.tipoRadarProjeto()) {
          //   if (this._radarLista.length > 0)
          //     model = this.buildUrl({ tags: this._radarLista.map(x => x.Titulo) });
          //   else
          //     model = this.buildUrl({ tags: [this.radar.Titulo] });
          // }
          if (this.tipoRadarProjeto()) {
            if (this.listaTweets.length > 5) {
              this.carregandoTweets = false;
              return;
            } else {
              var tituloProposicaoLista = this.listaProposicoes.map(
                (x) => x.Titulo
              );

              model = this.buildUrl({
                tags: [...[this.radar.Titulo]],
              });
              if (model != 'tags=') {
                this.twitterService.listarPorTag(model).then((data2) => {
                  if (!this.listaTweets) this.listaTweets = [];
                  this.listaTweets = this.listaTweets
                    .concat(data2)
                    .sort(function (a: any, b: any) {
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return (
                        (new Date(b.DataPublicacao) as any) -
                        (new Date(a.DataPublicacao) as any)
                      );
                    });
                  this.carregarHtmlTweets();
                  setTimeout(() => {
                    (window as any).twttr.widgets.load();
                  }, 3000);
                  this.carregandoTweets = false;
                });
              }
            }
          }
        });
    }
  }
  carregarStakeholders() {
    if (this.listaProposicoes) {
      this.radarMonitoramentoService
        .listarStakeholders(this.listaProposicoes)
        .then((data) => {
          this.stakeholderLista = data as any[];
          this.stakeholderAutorLista = (data as any[]).filter(
            (x) => x.Tipo == 'Autoria'
          );
          this.stakeholderProximidadeLista = (data as any[]).filter(
            (x) => x.Tipo == 'Proximidade'
          );
          this.stakeholderRelatorLista = (data as any[]).filter(
            (x) => x.Tipo == 'Relatoria'
          );
          this.stakeholderAutorDocumentoLista = (data as any[]).filter(
            (x) => x.Tipo == 'AutorDocumentos'
          );
        });
    }
  }
  diarioClick(diario) {
    window.open(diario.Url, '_system');
  }

  shareDiario(diario) {
    var texto = `${diario.Texto}`;
    var link = diario.Url;
    Share.share({
      title: diario.Titulo,
      text: texto.replace('<b><mark>', '*').replace('</mark></b>', '*'),
      url: link,
      dialogTitle: 'Compartilhe o legislativo onde quiser!',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }

  shareBill(proposicao) {
    var texto = `${proposicao.Title}`;
    var link = proposicao.congressdotgov_url;
    Share.share({
      title: proposicao.number,
      text: texto,
      url: link,
      dialogTitle: 'Dá uma olhada nesse projeto de lei dos EUA',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }
  carregarPregoes() {
    // this.diarioOficialService.listarPregoes(this.usuarioService.get().Id, this.buildUrl({ Tags: this.radar.Tags.slice(0, 10) })).then((data: any[]) => {
    //   this.pregoesLista = data;
    // });
  }

  carregarProgressoDiario() {
    this.columnChart7 = undefined;
    this.arrayEvolucaoDiario = undefined;
    if (this._radarLista && this._radarLista.length > 1) {
      this.diarioOficialService
        .listarProgresso(this.usuarioService.get().Id)
        .then((data) => {
          this.exibeProgressoDiario = false;
          this.exibirSpinning = false;
          var data2 = data as any;
          this.arrayEvolucaoDiario = data2.ProgressoAtual;
          this.columnChart7 = {
            chartType: 'ScatterChart',
            dataTable: this.arrayEvolucaoDiario,
            options: {
              width: 900,
              chartArea: { width: '800' },
              vAxis: {
                titleTextStyle: { color: '#069' },
              },
              hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
              lineWidth: 1,
              legend: {
                position: 'in',
              },
            },
          };
        });
    } else {
      this.diarioOficialService
        .listarProgressoTag(this.buildUrl({ Tags: this.radar.Tags }))
        .then((data) => {
          this.exibeProgressoDiario = false;
          this.exibirSpinning = false;
          var data2 = data as any;
          this.arrayEvolucaoDiario = data2.ProgressoAtual;
          this.columnChart7 = {
            chartType: 'ScatterChart',
            dataTable: this.arrayEvolucaoDiario,
            options: {
              width: 900,
              chartArea: { width: '800' },
              hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
              lineWidth: 1,
              legend: {
                position: 'in',
              },
            },
          };
        });
    }
  }
  removerFiltroDataApresentacao() {
    this.filtroDataApresentacaoTxt = undefined;
    this.listaProposicoes = this.listaProposicoesBkp;
    this.doInfinite(undefined);
  }
  async filtroDataApresentacaoClick() {
    if (!this.listaProposicoesBkp || this.listaProposicoesBkp.length == 0)
      this.listaProposicoesBkp = this.listaProposicoes;
    var data = new Date();
    const actionSheet = await this.actionSheetController.create({
      header: '📅 Visualizar proposições no período:',
      cssClass: '',
      buttons: [
        {
          text: 'Apresentadas últimos 7 dias',
          handler: () => {
            this.filtroDataApresentacaoTxt = 'Apresentada últimos 7 dias';
            data.setDate(data.getDate() - 7);
            this.listaProposicoes = this.listaProposicoes.filter(
              (x) => new Date(x.DataApresentacao).getTime() >= data.getTime()
            );
            this.doInfinite(undefined);
          },
        },
        {
          text: 'Apresentadas últimos 15 dias',
          handler: () => {
            this.filtroDataApresentacaoTxt = 'Apresentada últimos 15 dias';
            data.setDate(data.getDate() - 15);
            this.listaProposicoes = this.listaProposicoes.filter(
              (x) => new Date(x.DataApresentacao).getTime() >= data.getTime()
            );
            this.doInfinite(undefined);
          },
        },
        {
          text: 'Apresentadas últimos 30 dias',
          handler: () => {
            this.filtroDataApresentacaoTxt = 'Apresentada últimos 30 dias';
            data.setDate(data.getDate() - 30);
            this.listaProposicoes = this.listaProposicoes.filter(
              (x) => new Date(x.DataApresentacao).getTime() >= data.getTime()
            );
            this.doInfinite(undefined);
          },
        },
        {
          text: 'Tramitadas últimos 7 dias',
          handler: () => {
            this.filtroDataApresentacaoTxt = 'Tramitadas últimos 7 dias';
            data.setDate(data.getDate() - 7);
            this.listaProposicoes = this.listaProposicoes.filter(
              (x) => new Date(x.DataUltimoStatus).getTime() >= data.getTime()
            );
            this.doInfinite(undefined);
          },
        },
        {
          text: 'Tramitadas últimos 15 dias',
          handler: () => {
            this.filtroDataApresentacaoTxt = 'Tramitadas últimos 15 dias';
            data.setDate(data.getDate() - 15);
            this.listaProposicoes = this.listaProposicoes.filter(
              (x) => new Date(x.DataUltimoStatus).getTime() >= data.getTime()
            );
            this.doInfinite(undefined);
          },
        },
        {
          text: 'Tramitadas últimos 30 dias',
          handler: () => {
            this.filtroDataApresentacaoTxt = 'Tramitadas últimos 30 dias';
            data.setDate(data.getDate() - 30);
            this.listaProposicoes = this.listaProposicoes.filter(
              (x) => new Date(x.DataUltimoStatus).getTime() >= data.getTime()
            );
            this.doInfinite(undefined);
          },
        },
        // {
        //   text: 'Personalizado',
        //   icon: 'calendar-outline',
        //   handler: () => {
        //     // this.criarLink(radar);
        //   },
        // },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  carregarDiarioRecursivo() {
    var tag = this.tagsPopDou.pop();
    this.diarioOficialService
      .listarPorTag(tag, this.buildUrl(this.radar))
      .then((data: any[]) => {
        data = data.map((x) => {
          x.Tag = tag;
          return x;
        });
        this.diarioListaBkp = this.diarioListaBkp.concat(data as any[]);
        this.diarioLista = this.diarioListaBkp.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (new Date(b.Data) as any) - (new Date(a.Data) as any);
        });
      })
      .finally(() => {
        if (this.tagsPopDou.length > 0 && !this.exibirSpinning) {
          this.carregarDiarioRecursivo();
        } else {
          this.carregandoDOU = false;
          this.diarioLista = this.diarioListaBkp.sort(function (
            a: any,
            b: any
          ) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (new Date(b.Data) as any) - (new Date(a.Data) as any);
          });
          this.diarioLista = this.diarioListaBkp;
          this.diarioFiltroLista = this.diarioListaBkp
            .map((x) => x.Secao)
            .filter(this.onlyUnique);
          this.diarioOrgaoFiltroLista = this.diarioListaBkp
            .map((x) => x.Orgao)
            .filter(this.onlyUnique);

          this.diarioOrgaoFiltroLista.unshift('Todos os orgãos');
          this.diarioFiltroLista.unshift('Todas as seções do DOU');
          this.diarioFiltroEstadoLista = this.diarioListaBkp
            .map((x) => x.UF)
            .filter(this.onlyUnique);
          this.diarioFiltroEstadoLista.unshift('Federal e Todos os estados');
        }
      });
  }

  carregarDiarioOficial() {
    if (this.radar.Tags < 10) this.carregarPregoes();
    this.carregandoDOU = true;
    // this.carregarProgressoDiario();
    this.diarioListaBkp = [];
    if (this.radar) {
      this.tagsPopDou = [...this.radar.Tags];
      this.diarioFiltroLista = {};
      this.diarioFiltroEstadoLista = {};
      this.carregarDiarioRecursivo();
    }
  }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  carregarHtmlTweets() {
    if (this.listaTweets) {
      var that = this;
      this.zone.run(() => {
        setTimeout(() => {
          that.ocultarLoading();
        }, 10000);
      });
      var size = 30 > this.listaTweets.length ? this.listaTweets.length : 30;
      var that = this;
      this.zone.run(() => {
        this.listaTweets.slice(0, size).forEach(function (e, index) {
          that.parlamentarService.obterTwitterHtml(e).then((html) => {
            that.listaTweets[index].html = html;
            if (index == size - 1) {
              setTimeout(() => {
                (window as any).twttr.widgets.load();
              }, 1500);
            }
          });
        });
      });
    }
  }

  tipoRadarProjeto() {
    return (
      this.radarProjeto ||
      (((this.radar.Autor.length == 0 && this.radar.Tags.length == 0) ||
        (this.radarLista &&
          this.radarLista.filter((x) => x.Tags.length == 0).length > 0)) &&
        ((this.radar && this.radar.Id) ||
          (this._radarLista && this._radarLista.length > 0)))
    );
  }

  legislativo() {
    return (
      this.listaProposicoes.length > 0 &&
      (this.radar.Camara || this.radar.Senado)
    );
  }
  modoRadarProjeto() {
    this.radarProjeto = true;
    this.tagsBkp = this.radar.AssuntoItem;
    this.radar.AssuntoItem = '';
    this.radar.Tags = [];
  }
  modoRadarTag() {
    this.radarProjeto = false;
    this.radar.AssuntoItem = this.tagsBkp;
  }
  async carregarListaRadar() {
    if (this._radarLista && this.radarLista.length > 0) {
      var tagsLista = this._radarLista.flatMap((x) => x.Tags).filter((x) => x);
      if (
        tagsLista.length >= this._radarLista.length ||
        this._radarLista.length == 1
      ) {
        this.radar.Tags = this._radarLista
          .flatMap((x) => x.Tags)
          .filter((x) => x);
      } else this.radar.Tags = [];
      this.listaTweets = [];
      this.diarioLista = [];
      this.diarioListaBkp = [];
      this.documentoLista = [];
      this.ordemDiaLista = [];
      this.noticiaLista = [];
      this.discursoLista = [];
      this.listaProposicoes = [];
      var radarLista = this.radarLista.filter(
        (x) => x.Titulo != 'Todos os radares'
      );
      this.exibirSpinning = true;
      this.carregandoProposicao = true;
      if (
        localStorage.getItem('PROPOSICAO_RADAR_LISTA') &&
        this.listaProposicoes.length == 0
      ) {
        this.listaProposicoes = JSON.parse(
          localStorage.getItem('PROPOSICAO_RADAR_LISTA')
        );
        this.exibirSpinning = false;
        this.listaProposicoesPaginacao = this.listaProposicoes.slice(
          0,
          this.paginaAtual
        );
      }
      for (let index = 0; index < radarLista.length; index++) {
        var radar = radarLista[index];
        if (radar.Tags.length == 0) continue;
        if (radar.DOU) this.carregarDiarioOficial();
        this.carregandoOrdemDia = true;
        this.carregandoDiscurso = true;
        this.carregandoDocumento = true;
        this.carregandoNoticia = true;
        this.carregandoTweets = true;
        var func = this.radarMonitoramentoService.previsualizarIndex(radar);
        await func.then((data: any) => {
          this.listaProposicoes = [...this.listaProposicoes, ...data];
          this.listaProposicoes = [
            ...new Map(
              this.listaProposicoes.map((item) => [item['Id'], item])
            ).values(),
          ];

          this.exibirSpinning = this.listaProposicoes.length == 0;
          if (index == radarLista.length - 1) {
            this.carregandoProposicao = false;
            this.listaProposicoes = this.listaProposicoes.sort(function (
              a: any,
              b: any
            ) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return (
                (new Date(b.DataUltimoStatus) as any) -
                (new Date(a.DataUltimoStatus) as any)
              );
            });
            try {
              localStorage.setItem(
                'PROPOSICAO_RADAR_LISTA',
                JSON.stringify(this.listaProposicoes)
              );
            } catch (error) {}

            this.segmentoChange({ detail: { value: 'proposicoes' } });
            this.carregarCountProposicoes();
            this.carregarListaDocumento();
            this.carregarListaNoticia(this.listaProposicoes);
            this.carregarTweets();
            var tituloProposicaoLista = this.listaProposicoes.map(
              (x) => x.Titulo
            );

            this.tagsPopOrdemDia = [
              ...this.radar.Tags,
              ...[this.radar.Titulo],
            ].filter((x) => x);
            this.carregarListaOrdemDia();
            this.carregarAgendaSessao();
            this.carregarStakeholders();
            this.carregarInsights();
            this.carregarListaDiscurso(this.listaProposicoes);
            this.carregarListaVotacao(this.listaProposicoes);
            this.autoCompleteProjetoRadarListaBkp = this.listaProposicoes;
            this.iniciaInfinite();
          }
          this.listaProposicoesPaginacao = this.listaProposicoes.slice(
            0,
            this.paginaAtual
          );
          this.exibirSpinning = false;
        });
      }
    }
  }
  filtrarProposicaoIgnorada() {
    if (!this.listaProposicoes) return;
    var idlistaProposicoesIgnoradas = this.listaProposicoesIgnoradas.map(
      (x) => x.Id
    );
    if (idlistaProposicoesIgnoradas.length == 0) return;
    this.listaProposicoes = this.listaProposicoes.filter(
      (x) => idlistaProposicoesIgnoradas.indexOf(x.Id) == -1
    );
    this.listaProposicoesBkp = this.listaProposicoes;
  }
  async preVisualizar() {
    this.radar.DOU = false;
    this.isItemAvailableProposicao = false;
    if (this.radar.AssuntoItem) {
      if (this.radar.AssuntoItem.indexOf(',') >= 0) {
        this.radar.AssuntoItem.split(',').forEach((assunto) => {
          this.radar.Tags.push(assunto);
        });
      } else {
        this.radar.Tags.push(this.radar.AssuntoItem);
      }
      this.radar.AssuntoItem = '';
    }

    if (this.radar.AutorItem) {
      this.radar.Autor.push(this.radar.AutorItem);
      this.radar.AutorItem = '';
    }
    await this.carregarListaRadar();
    if (!this.tipoRadarProjeto() && !this.radarLista) {
      if (this.radar.Tags.length == 0) return;
      this.exibirSpinning = true;
      this.radarMonitoramentoService.preVisualizar(this.radar).then((data) => {
        this.listaProposicoes = data as any[];
        this.listaProposicoesBkp = this.listaProposicoes;
        this.filtrarProposicaoIgnorada();
        this.iniciaInfinite();
        this.carregarTweets();
        this.carregarCountProposicoes();
        if (this.radar.Camara || this.radar.Senado) {
          this.carregarAgendaSessao();
          this.carregarListaVotacao(this.listaProposicoes);
        }
        this.carregarStakeholders();
        this.carregarListaDocumento();
        this.tagsPopDou = [...this.radar.Tags];
        this.tagsPopOrdemDia = [...this.radar.Tags];
        this.ordemDiaLista = [];
        this.diarioLista = [];
        this.diarioListaPaginacao = [];
        this.discursoLista = [];
        // this.tagsPopOrdemDia = [...this.listaProposicoes.map(x => x.Titulo), ...this.radar.Tags];

        this.carregarListaOrdemDia();
        this.carregarListaCongressoEua(this.listaProposicoes);
        this.carregarListaNoticia(this.listaProposicoes);
        this.carregarListaDiscurso(this.listaProposicoes);
        this.carregarInsights();
        if (this.radar.DOU) this.carregarDiarioOficial();
        this.segmentoChange({ detail: { value: 'proposicoes' } });
        this.exibirSpinning = false;
      });
    }
    this.carregarRadarProjeto();
    this.carregarProposicaoIgnorada();
  }
  carregarProposicaoIgnorada() {
    if (!this._radarLista || (this._radarLista?.length == 0 && this.radar.Id)) {
      this.projetoService
        .listarProposicaoIgnorada(this.radar.Id)
        .then((data: any) => {
          this.listaProposicoesIgnoradas = data;
          this.filtrarProposicaoIgnorada();
        });
    }
  }
  async carregarRadarProjeto() {
    if (this.tipoRadarProjeto()) {
      var radar = this._radarLista
        ? this._radarLista.filter((x) => x.Tags.length == 0).map((x) => x.Id)
        : [this.radar.Id];
      this.exibirSpinning = true;

      this.carregandoProposicao = true;
      this.carregandoOrdemDia = true;
      this.carregandoDiscurso = true;
      this.carregandoDocumento = true;
      this.carregandoNoticia = true;
      this.carregandoTweets = true;

      await this.radarMonitoramentoService
        .preVisualizarProjetoLeiLista(radar)
        .then((data: any) => {
          if (!this.listaProposicoes) this.listaProposicoes = data;
          else this.listaProposicoes = [...this.listaProposicoes, ...data];
          this.carregandoProposicao = false;
          this.exibirSpinning = false;
          this.listaProposicoes = this.listaProposicoes.sort(function (
            a: any,
            b: any
          ) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              (new Date(b.DataUltimoStatus) as any) -
              (new Date(a.DataUltimoStatus) as any)
            );
          });
          this.segmentoChange({ detail: { value: 'proposicoes' } });
          this.carregarCountProposicoes();
          this.carregarListaDocumento();
          this.carregarListaNoticia(this.listaProposicoes);
          this.carregarTweets();
          var tituloProposicaoLista = this.listaProposicoes.map(
            (x) => x.Titulo
          );

          this.tagsPopOrdemDia = [
            ...this.radar.Tags,
            ...[this.radar.Titulo],
          ].filter((x) => x);
          this.carregarListaOrdemDia();
          this.carregarAgendaSessao();
          this.carregarStakeholders();
          this.carregarInsights();
          this.carregarListaDiscurso(this.listaProposicoes);
          this.carregarListaVotacao(this.listaProposicoes);
          if (this.autoCompleteProjetoRadarListaBkp)
            this.autoCompleteProjetoRadarListaBkp =
              this.autoCompleteProjetoRadarListaBkp.concat(
                this.listaProposicoes
              );
          else this.autoCompleteProjetoRadarListaBkp = this.listaProposicoes;
          this.exibirSpinning = this.listaProposicoes.length == 0;
          this.iniciaInfinite();
        });

      this.insights = undefined;
      this.segmentoChange({ detail: { value: 'proposicoes' } });
    }
  }

  adicionarOutroAutor() {
    if (this.radar.AutorItem) {
      this.radar.Autor.push(this.radar.AutorItem);
      this.radar.AutorItem = '';
      this.preVisualizar();
    }
  }

  adicionarOutroAssunto() {
    if (this.radar.AssuntoItem) {
      this.radar.Tags.push(this.radar.AssuntoItem);
      this.radar.AssuntoItem = '';
      this.preVisualizar();
    }
  }

  documentoClick(documento) {
    var url = documento.DocumentoProjeto.Link.replace('http:', 'https:');
    window.open(url, '_system');
  }

  formularioValido() {
    return (
      ((this.radar.Tags && this.radar.Tags.length > 0) ||
        (this.radar.Autor && this.radar.Autor.length > 0) ||
        this.listaProposicoes?.length > 0) &&
      !this.somenteLeitura
    );
  }

  excluirAutor(autor) {
    this.zone.run(() => {
      this.radar.Autor = this.radar.Autor.filter((x) => x !== autor);
      this.preVisualizar();
    });
  }
  secaoDouChange($event) {
    if (
      $event &&
      $event.detail.value !== false &&
      $event.detail.value !== 'Todas as seções do DOU'
    ) {
      this.diarioLista = this.diarioListaBkp.filter(
        (x) => x.Secao == $event.detail.value
      );
      this.diarioListaPaginacao = this.diarioLista.slice(
        0,
        this.paginaAtualDiario
      );
    } else {
      this.zone.run(async () => {
        this.diarioLista = this.diarioListaBkp;
      });
    }
  }
  diarioOrgaoChange($event) {
    if (
      $event &&
      $event.detail.value !== false &&
      $event.detail.value !== 'Todos os orgãos'
    ) {
      this.diarioLista = this.diarioListaBkp.filter(
        (x) => x.Orgao == $event.detail.value
      );
      this.diarioListaPaginacao = this.diarioLista.slice(
        0,
        this.paginaAtualDiario
      );
    } else {
      this.zone.run(async () => {
        this.diarioLista = this.diarioListaBkp;
      });
    }
  }

  estadoDouChange($event) {
    if (
      $event &&
      $event.detail.value !== false &&
      $event.detail.value !== 'Federal e Todos os estados'
    ) {
      this.diarioLista = this.diarioListaBkp.filter(
        (x) => x.UF == $event.detail.value
      );
      this.diarioListaPaginacao = this.diarioLista.slice(
        0,
        this.paginaAtualDiario
      );
    } else {
      this.zone.run(async () => {
        this.diarioLista = this.diarioListaBkp;
      });
    }
  }

  compareWithDiario(o1: any, o2: any) {
    if (o1 === 'Todas as seções do DOU' || o1 === true) return true;
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  compareWithDiarioOrgao(o1: any, o2: any) {
    if (o1 === 'Todos os orgãos' || o1 === true) return true;
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  compareWithEstadoDiario(o1: any, o2: any) {
    if (o1 === 'Federal e Todos os estados' || o1 === true) return true;
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  async filtrarAssunto(assunto) {
    if (!this.filtroAssunto) {
      const actionSheet = await this.actionSheetController.create({
        cssClass: '',
        buttons: [
          {
            text: 'Filtrar por ' + assunto,
            icon: 'funnel-outline',
            handler: () => {
              this.zone.run(() => {
                this.filtroAssunto = assunto;
                this.radar.TagsBkp = this.radar.Tags;
                this.radar.Tags = this.radar.Tags.filter((x) => x === assunto);
                this.segmento = 'proposicoes';
                this.preVisualizar();
              });
            },
          },
          {
            text: 'Excluir',
            role: 'destructive',
            icon: 'trash',
            handler: () => {
              this.excluirAssunto(assunto);
            },
          },
          {
            text: 'Cancelar',
            icon: 'close',
            role: 'cancel',
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    } else {
      this.filtroAssunto = false;
      this.radar.Tags = this.radar.TagsBkp;
      this.radar.TagsBkp = undefined;
      this.preVisualizar();
    }
  }
  excluirAssunto(assunto) {
    this.zone.run(() => {
      this.radar.Tags = this.radar.Tags.filter((x) => x !== assunto);
      if (this.radar.Tags.length == 0) {
        this.isItemAvailableProjetoRadar = false;
        this.listaProposicoes = [];
        this.listaProposicoesBkp = [];
        this.listaProposicoesPaginacao = [];
      }
      this.preVisualizar();
    });
  }
}
