import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  standalone: false,
  styleUrls: ['./story.component.scss'],
})
export class StoryComponent implements OnInit {
  @Input()
  stakeholderList: any[];

  @Input()
  scope: any[];

  @Input()
  stakeHolderClick: any;
  nomeStakeholderSelecionado: any;
  constructor() {}
  click(stakeholder) {
    if (this.nomeStakeholderSelecionado == stakeholder.Nome) {
      this.nomeStakeholderSelecionado = '';
    } else {
      this.nomeStakeholderSelecionado = stakeholder.Nome;
    }
    this.stakeHolderClick(this.nomeStakeholderSelecionado , this.scope);
  }
  ngOnInit() {}
  popoverDidPresent($event: any) {
    // setTimeout(() => {
    //   $event.target.dismiss();
    // }, 2000);
  }
}
