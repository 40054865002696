import { Component, Input, NgZone, OnInit } from '@angular/core';
import {
  ActionSheetController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { ProjetoService } from '../api/projeto.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { LoadingController, AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ParlamentarService } from '../api/parlamentar.service';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { UsuarioService } from '../api/usuario.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { ComissaoService } from '../api/comissao.service';
import { ComissaoPage } from '../comissao/comissao.page';
import { VotoParlamentarPage } from '../voto-parlamentar/voto-parlamentar.page';
import { SessaoPlenarioService } from '../api/sessao-plenario.service';
import { CriterioRankingPage } from '../criterio-ranking/criterio-ranking.page';
import { AssinaturaService } from '../api/assinatura.service';
import { Share } from '@capacitor/share';
import { Title } from '@angular/platform-browser';
import { CampoService } from '../api/campo.service';
import { DiscursoService } from '../api/discurso.service';
import { ParlamentarVisualizadoService } from '../api/parlamentar-visualizado.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@Component({
  selector: 'app-parlamentar',
  templateUrl: './parlamentar.component.html',
  standalone: false,
  styleUrls: ['./parlamentar.component.scss'],
})
export class ParlamentarComponent extends PageBaseService implements OnInit {
  @Input()
  parlamentar: any;
  tweetLista: any;
  tweetListaBkp: any;
  filtroTweet: any;
  public columnChart1: GoogleChartInterface;
  public columnChart2: GoogleChartInterface;
  votoSlice: any;
  width = this.platform.width() as any;
  envolvimentoSlice: any;
  android: boolean;
  envolvimentoSelected;
  radarCadastrado = false;
  arrayEvolucao: any[][];
  tendenciaLista: unknown;
  segmento: any;
  comissaoLista: any[];
  votoLista: unknown;
  envolvimentoLista: any;
  membroGabineteLista: any[];
  htmlContent: unknown;
  plano = null;
  nativePlatform = true;
  proximidadeList: any[][];
  envolvimentoListaOpt: any;
  EnvolvimentoVMListaBkp: any;
  discursoLista: any;
  fidelidadePartidaria: any;

  constructor(
    private vibration: Vibration,
    private toastController: ToastController,
    private usuarioService: UsuarioService,
    private comissaoService: ComissaoService,
    private discursoService: DiscursoService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    public modalController: ModalController,
    protected platform: Platform,
    protected router: Router,
    protected loadingCtrl: LoadingController,
    protected alertCtrl: AlertController,
    private projetoService: ProjetoService,
    private parlamentarVisualizadoService: ParlamentarVisualizadoService,
    private PageBaseService: PageBaseService,
    private titleService: Title,
    private zone: NgZone,
    private parlamentarService: ParlamentarService,
    private sessaoPlenarioService: SessaoPlenarioService,
    private assinaturaService: AssinaturaService,
    private route: ActivatedRoute,
    private campoService: CampoService,
    public actionSheetController: ActionSheetController
  ) {
    super(loadingCtrl, alertCtrl, platform, router);
  }

  ngOnInit() {
    var id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.nativePlatform = false;
      this.parlamentarService.obter({ Id: id }).then((data) => {
        this.parlamentar = data;
        this.carregar();
      });
    } else {
      this.carregar();
    }
  }

  carregar() {
    var parlamentarVisualizado = {
      IdParlamentar: this.parlamentar.Id,
      Usuario: this.usuarioService.get().Email,
    };
    this.parlamentarVisualizadoService.criar(parlamentarVisualizado);
    // this.parlamentar.YoutubeKey = ' http://www.youtube.com/embed/?listType=user_uploads&list=' + this.parlamentar.Youtube.split('/').slice(-1);
    this.segmento = 'dado';
    this.titleService.setTitle(
      'Legislapp • ' + this.parlamentar.Nome + ' - ' + this.parlamentar.Partido
    );
    this.carregarComissao();
    this.carregarFidelidadePartidaria();
    this.carregarVotacao();
    this.carregarEnvolvimento();
    this.carregarCampos();
    this.carregarTweets();
    this.carregarGabinete();
    this.carregarListaDiscurso();
    this.android = this.platform.is('android');
  }
  async editarCampo(chave) {
    var valor = this.parlamentar.Schema[chave];
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Alterar campo customizado',
      subHeader: 'Digite o novo valor do campo ' + chave,
      inputs: [
        {
          name: 'valor',
          type: 'text',
          placeholder: 'Valor atual: ' + valor,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (model) => {
            this.parlamentar.Schema[chave] = model.valor;
            if (this.parlamentar.Campo.Id) {
              this.campoService
                .alterar({
                  Id: this.parlamentar.Campo.Id,
                  IdProjetoLei: this.parlamentar.Id,
                  IdUsuario: this.usuarioService.get().Id,
                  Schema: JSON.stringify(this.parlamentar.Schema),
                })
                .then((data: any[]) => {
                  this.carregarCampos();
                });
            }
          },
        },
      ],
    });
    alertComponent.present();
  }
  async campoClick(campo) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Campo ' + campo,
      cssClass: '',
      buttons: [
        {
          text: 'Editar Valor',
          icon: 'pencil',
          handler: () => {
            this.editarCampo(campo);
          },
        },
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            delete this.parlamentar.Schema[campo];
            this.campoService
              .alterar({
                Id: this.parlamentar.Campo.Id,
                IdParlamentar: this.parlamentar.Id,
                IdUsuario: this.usuarioService.get().Id,
                Schema: JSON.stringify(this.parlamentar.Schema),
              })
              .then((data: any[]) => {
                this.carregarCampos();
              });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  async addCampo() {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Adicione campos customizados à matéria',
      subHeader: 'Somente você e sua equipe poderão ver',
      inputs: [
        {
          name: 'nome',
          type: 'text',
          placeholder: 'Nome do seu campo, exemplo: Posicionamento',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (model) => {
            this.addValorCampo(model.nome);
          },
        },
      ],
    });
    alertComponent.present();
  }

  carregarCampos() {
    if (this.usuarioService.get()) {
      this.campoService
        .listarParlamentar(this.usuarioService.get().Id, this.parlamentar.Id)
        .then((data: any) => {
          if (data == null) data = { Schema: '{}' };
          this.parlamentar.SchemaKeys = Object.keys(JSON.parse(data.Schema));
          this.parlamentar.Schema = JSON.parse(data.Schema);
          this.parlamentar.Campo = data;
        });
    }
  }

  async addValorCampo(nome) {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Adicione campos customizados à matéria',
      subHeader:
        'Deseja adicionar algum valor inicial? Se quiser, você pode fazer isso depois',
      inputs: [
        {
          name: 'valor',
          type: 'text',
          placeholder: 'Valor do campo ' + nome,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (model) => {
            this.parlamentar.Schema[nome] = model.valor;
            if (this.parlamentar.Campo.Id) {
              this.campoService
                .alterar({
                  Id: this.parlamentar.Campo.Id,
                  IdProjetoLei: this.parlamentar.Id,
                  IdUsuario: this.usuarioService.get().Id,
                  Schema: JSON.stringify(this.parlamentar.Schema),
                })
                .then((data: any[]) => {
                  this.carregarCampos();
                });
            } else {
              this.campoService
                .inserir({
                  IdParlamentar: this.parlamentar.Id,
                  IdUsuario: this.usuarioService.get().Id,
                  Schema: JSON.stringify(this.parlamentar.Schema),
                })
                .then((data: any[]) => {
                  this.carregarCampos();
                });
            }
          },
        },
      ],
    });
    alertComponent.present();
  }

  ionViewDidEnter() {
    this.instagramRender();
    this.twitterRender();
    this.progressaoProposicao();
    this.carregarGraficoProximidade();
  }

  compareWith(o1: any, o2: any) {
    if (o2 === true) return true;
    return o1 === o2;
  }

  carregarListaDiscurso() {
    this.discursoService
      .listarPorAutor(this.parlamentar.Nome)
      .then((data: any[]) => {
        this.discursoLista = data;
      });
  }

  ionViewWillEnter() {
    this.zone.run(() => {
      if (this.usuarioService.get()) {
        this.assinaturaService.inicializar(this.usuarioService.get().Id);
        this.usuarioService
          .obterPlano(this.usuarioService.get().Id)
          .then((data) => {
            this.plano = data;
            if (this.plano == null) this.plano = false;
            if (data == null) {
              this.plano = false;
            }
          });
      }
    });

    this.parlamentarService
      .listarTendencia(this.parlamentar.Id)
      .then((data: any[]) => {
        this.tendenciaLista = data.map((x) => x.Value);
      });
  }

  envolvimentoChange($event) {
    if ($event && $event.detail.value !== false) {
      if ($event.detail.value != 'Todos os envolvimentos') {
        this.envolvimentoLista = this.EnvolvimentoVMListaBkp.filter(
          (x) => x.TipoEnvolvimento == $event.detail.value
        );
      } else {
        this.envolvimentoLista = this.EnvolvimentoVMListaBkp;
      }
    }
  }

  async informacaoRankingRelevanciaClick() {
    const modal = await this.modalController.create({
      component: CriterioRankingPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async comissaoClick(comissao) {
    this.exibirLoading('Carregando...');
    this.comissaoService.obter({ Id: comissao.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  progressaoProposicao() {
    this.parlamentarService
      .listarProgressaoProposicao({ IdParlamentar: this.parlamentar.Id })
      .then((data) => {
        this.arrayEvolucao = (data as any[]).map((x) => [
          x.Key.toString(),
          x.Value as number,
        ]);
        this.arrayEvolucao.unshift(['Mês/Ano', 'Novas matérias']);
        this.columnChart1 = {
          chartType: 'ScatterChart',
          dataTable: this.arrayEvolucao,
          options: {
            width: 900,
            chartArea: { width: '800' },
            vAxis: {
              title: 'Novas matérias',
              titleTextStyle: { color: '#069' },
            },
            hAxis: { title: 'Mês/Ano', titleTextStyle: { color: '#069' } },
            lineWidth: 1,
            legend: {
              position: 'left',
            },
          },
        };
      });
  }

  fechar() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({});
  }

  redeSocialClick(url) {
    // this.rateService.exibir();
    window.open(url, '_system');
  }

  share(parlamentar) {
    var texto = `Dá uma olhada no perfil do(a) ${
      parlamentar.Nome +
      (parlamentar.Partido || parlamentar.UF
        ? ' (' +
          (parlamentar.Partido + (parlamentar.UF ? '/' + parlamentar.UF : '')) +
          ')'
        : '')
    }`;
    var link = `https://legislapp.com.br/app/Parlamentar/Visualizar/${parlamentar.Id}`;
    Share.share({
      title: 'Dá uma olhada nessa publicação',
      text: texto,
      url: link,
      dialogTitle: 'Compartilhe o legislativo onde quiser!',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }
  onImgError(item) {
    if (!item.Autoria) item.Autoria = item.Autor;
    if (item.Autoria == 'Senado Federal') {
      item.Foto = '/assets/logo-senado.png';
    } else if (
      item.Autoria == 'Câmara dos Deputados' ||
      item.Autoria == 'Câmara Dos Deputados'
    ) {
      item.Foto = '/assets/logo-camara.png';
    } else if (
      item.Autoria == 'Poder Executivo' ||
      item.Autoria == 'Presidência da República'
    ) {
      item.Foto = '/assets/poderexecutivo.png';
    } else if (
      item.Autoria &&
      item.Autoria.toUpperCase().indexOf('COMISSÃO') > -1
    ) {
      item.Foto = '/assets/comissoes.png';
    } else {
      item.Foto = '/assets/imagem-perfil.jpg';
    }
    item.UrlFotoAutor = item.Foto;
  }

  membroGabineteClick(membroGabinete) {}

  envolvimentoClick(envolvimento) {
    this.PageBaseService.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: envolvimento.IdProjeto })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.PageBaseService.ocultarLoading();
        return await modal.present();
      });
  }
  carregarComissao() {
    this.comissaoService
      .listarPorParlamentar(this.parlamentar.Id)
      .then((data) => {
        this.comissaoLista = data as any[];
        this.ocultarLoading();
      });
  }
  carregarFidelidadePartidaria() {
    this.parlamentarService
      .obterFidelidadePartidaria(this.parlamentar.Id)
      .then((data) => {
        this.fidelidadePartidaria = data;
      });
  }

  carregarGabinete() {
    this.parlamentarService
      .listarMembroGabinete(this.parlamentar.Id)
      .then((data) => {
        this.membroGabineteLista = data as any[];
        this.ocultarLoading();
      });
  }

  carregarVotacao() {
    this.sessaoPlenarioService
      .listarPorParlamentar(this.parlamentar.Id)
      .then((data) => {
        this.votoLista = data;
        this.ocultarLoading();
      });
  }

  carregarEnvolvimento() {
    this.parlamentarService
      .listarEnvolvimento(this.parlamentar.Id)
      .then((data: any) => {
        if (data) {
          this.EnvolvimentoVMListaBkp = data;
          this.envolvimentoLista = data;
          this.envolvimentoListaOpt = data
            .map((x) => x.TipoEnvolvimento)
            .filter((v, i, a) => a.indexOf(v) === i);
          this.envolvimentoSelected = 'Todos os envolvimentos';
          this.envolvimentoListaOpt.unshift('Todos os envolvimentos');
        }
        this.ocultarLoading();
      });
  }

  twitterRender() {
    !(function (d, s, id) {
      let js: any,
        fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    })(document, 'script', 'twitter-wjs');
  }

  ngAfterViewInit() {
    this.twitterRender();
  }

  instagramRender() {
    // try {
    //   (<any>window).instagram = (function (d, s, id) {
    //     let js: any;
    //     let fjs = d.getElementsByTagName(s)[0],
    //       i = (<any>window).instagram || {};
    //     if (!d.getElementById(id)) {
    //       js = d.createElement(s);
    //       js.setAttribute('onLoad', 'window.instgrm.Embeds.process()');
    //       js.id = id;
    //       js.src = 'https://platform.instagram.com/en_US/embeds.js';
    //       fjs.parentNode.insertBefore(js, fjs);
    //       i._e = [];
    //       i.ready = function (f: any) {
    //         i._e.push(f);
    //       };
    //       return i;
    //     }
    //   })(document, 'script', 'instagram-wjs');
    //   if ((<any>window).instagram.ready())
    //     (<any>window).instgrm.Embeds.process();
    // } catch (error) { }
  }
  pesquisarTweets() {
    this.carregarTweets();
  }

  getItemAutocompleteTweet(ev: any) {
    if (ev.target.value.length >= 3) {
      // Reset items back to all of the items
      this.tweetLista = this.tweetListaBkp;

      // set val to the value of the searchbar
      const val = ev.target.value;

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
        this.tweetLista = this.tweetListaBkp
          .filter((item) => {
            return item.Descricao.toLowerCase().indexOf(val.toLowerCase()) > -1;
          })
          .slice(0, 3);
        if (this.tweetLista.length > 0) {
          this.carregarTweets();
        }
      } else {
      }
    } else if (ev.target.value.length == 0) {
      this.limparPesquisaTweet();
    }
  }
  limparPesquisaTweet() {
    this.filtroTweet = '';
    var size = 20 > this.tweetListaBkp.length ? this.tweetListaBkp.length : 20;
    this.tweetLista = this.tweetListaBkp.slice(0, size);
    this.carregarTweets();
  }
  carregarTweets() {
    // this.exibirLoading('Carregando tweets...');
    var that = this;
    this.zone.run(() => {
      setTimeout(() => {
        // that.ocultarLoading();
      }, 5000);
    });
    if (this.tweetLista) {
      (window as any).twttr.widgets.load();
    } else {
      this.parlamentarService.listarTweets(this.parlamentar.Id).then((data) => {
        this.tweetLista = data as any[];
        this.tweetListaBkp = data as any[];
        var size = 20 > this.tweetLista.length ? this.tweetLista.length : 20;
        var that = this;
        this.zone.run(() => {
          this.tweetLista.slice(0, size).forEach(function (e, index) {
            that.parlamentarService.obterTwitterHtml(e).then((html) => {
              that.tweetLista[index].html = html;
              (window as any).twttr.widgets.load();
            });
          });
        });
      });
    }
  }
  carregarMencoes() {
    this.parlamentarService
      .listarMencaoTwitter(this.parlamentar.Id)
      .then((data) => {
        this.tweetLista = data as any[];
        var size = 20 > this.tweetLista.length ? this.tweetLista.length : 20;
        var that = this;
        this.zone.run(() => {
          this.tweetLista.slice(0, size).forEach(function (e, index) {
            that.parlamentarService.obterTwitterHtml(e).then((html: string) => {
              that.tweetLista[index].html = html.replace(
                'twitter-tweet',
                'twitter-tweet tw-align-center'
              );

              if (index == size - 1) (window as any).twttr.widgets.load();
            });
          });
        });
      });
  }
  segmentoChange($event) {
    if ($event) {
      this.segmento = $event.detail.value;
      if (this.segmento === 'comissao' && !this.comissaoLista) {
        this.exibirLoading('');
        this.carregarComissao();
      } else if (this.segmento === 'votacao' && !this.votoLista) {
        this.exibirLoading('');
        this.carregarVotacao();
      } else if (this.segmento === 'gabinete') {
        this.carregarGabinete();
      } else if (this.segmento === 'insights') {
      } else if (this.segmento === 'tweets') {
        this.carregarTweets();
        (window as any).twttr.widgets.load();
      } else if (this.segmento === 'mencoes') {
        this.carregarMencoes();
      }
      this.focusSegment(this.segmento);
    }
    try {
      this.zone.run(() => {
        if (this.usuarioService.get()) {
          this.usuarioService
            .obterPlano(this.usuarioService.get().Id)
            .then((data) => {
              this.plano = data;
              if (!this.plano) this.assinaturaService.assinar();
            });
        }
      });
    } catch (error) {}
  }

  focusSegment(segmentId) {
    setTimeout(() => {
      this.zone.run(() => {
        document.getElementById(segmentId).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
    }, 1000);
  }

  async votoClick(voto) {
    this.PageBaseService.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: voto.IdProjeto })
      .then(async (data: any) => {
        const modal = await this.modalController.create({
          component: VotoParlamentarPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            projetoSessaoPlenario: {
              CategoriaStr: data.CategoriaStr,
              Titulo: data.Titulo,
              Ementa: data.Ementa,
              Autoria: data.Autoria,
              Relator: data.Relator,
              Id: data.Id,
              DataApresentacaoFormatada: data.DataApresentacaoFormatada,
              Votacao: {
                Id: voto.IdSessaoPlenario,
                DescricaoSessao: voto.DescricaoSessao,
                DataFormatada: voto.DataFormatada,
                TextoAprovado: voto.Aprovado,
              },
            },
          },
        });
        this.PageBaseService.ocultarLoading();
        return await modal.present();
      });
  }
  async verRadarClick(tendencia) {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: RadarEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        tendencia,
      },
    });
    return await modal.present();
  }
  async criarRadar() {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header:
        'Clique em OK para criar o radar de monitoramento do(a) ' +
        this.parlamentar.Nome,
      inputs: [
        {
          name: 'nomeParlamentar',
          type: 'text',
          placeholder: 'Ex: PL 9999/2021',
          value: this.parlamentar.Nome,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (data) => {
            this.exibirLoading('Carregando...');
            this.radarMonitoramentoService
              .cadastrar({
                Camara: true,
                Senado: true,
                DOEDF: false,
                DOESP: false,
                DOERS: false,
                DOERJ: false,
                DOEBA: false,
                DOEAL: false,
                DOMUNSP: false,
                DOU: false,
                EUA: false,
                Autor: [],
                Tags: [this.parlamentar.Nome],
                Titulo: data.nomeParlamentar,
                AutorItem: '',
                AssuntoItem: '',
                IdUsuario: this.usuarioService.get().Id,
              })
              .then(async (data) => {
                this.vibration.vibrate(2000);
                const toast = await this.toastController.create({
                  message: '🎉 Radar salvo com sucesso!',
                  duration: 3000,
                  color: 'success',
                });
                toast.present();
                this.ocultarLoading();
                this.radarCadastrado = true;
              });
          },
        },
      ],
    });
    alertComponent.present();
    this.ocultarLoading();
  }

  carregarGraficoProximidade() {
    this.parlamentarService
      .listarProximidade(this.parlamentar.Id)
      .then((data) => {
        this.proximidadeList = (data as any[]).map((x) => [
          x.Tema.toString(),
          x.Qtd,
        ]);
        this.proximidadeList.unshift(['Parlamentar', '']);
        var width =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.1;
        var chartWidth =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.3;
        this.columnChart2 = {
          chartType: GoogleChartType.PieChart,
          dataTable: this.proximidadeList,
          options: {
            allowHtml: true,
            width: width,
            height: 250,
            chartArea: { width: chartWidth, height: '200' },
            legend: { position: 'right' },
          },
        };

        // var that = this;
        // setTimeout(() => {
        //   debugger;
        //   var imageURI = that.columnChart2.component!.wrapper.getChart().getImageURI();
        // }, 3000);
      });
  }
}
