import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-typeahead',
  templateUrl: './typeahead.page.html',
  styleUrls: ['./typeahead.page.scss'],
})
export class TypeaheadPage implements OnInit {
  @Input() items: any[] = [];
  @Input() selectedItems: string[] = [];
  @Input() title = 'Select Items';
  @Input() selectionChange;
  @Input() itemChange;
  @Input() selectionCancel;
  @Input() scope;

  @Output() selectionMark = new EventEmitter<string[]>();

  filteredItems: any[] = [];
  workingSelectedValues: string[] = [];

  ngOnInit() {
    this.filteredItems = [...this.items.slice(0, 50)];
    this.workingSelectedValues = [...this.selectedItems];
  }

  trackItems(index: number, item: any) {
    return item.value;
  }

  cancelChanges() {
    this.selectionCancel(this.scope);
  }

  confirmChanges() {
    this.selectionChange(this.workingSelectedValues, this.scope);
  }

  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (!searchQuery) {
      this.filteredItems = [...this.items.slice(0, 50)];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) => {
        return item.Titulo.toLowerCase().includes(normalizedQuery);
      }).slice(0, 50);
    }
  }

  isChecked(value: string) {
    return this.workingSelectedValues.find((item) => item === value);
  }

  checkboxChange(ev: any) {
    const { checked, value } = ev.detail;

    if (checked) {
      this.workingSelectedValues = [...this.workingSelectedValues, value];
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter(
        (item) => item !== value
      );
    }
    this.selectionMark.emit(this.workingSelectedValues);
    this.itemChange(this.workingSelectedValues, this.scope);
  }
}
